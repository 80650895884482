.loader,
.loader:after {
    display: block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 16px auto;
}

.loader {
    position: relative;
    border-top: 4px solid rgba(26, 26, 26, 0.5);
    border-right: 4px solid rgba(26, 26, 26, 0.5);
    border-bottom: 4px solid rgba(26, 26, 26, 0.5);
    border-left: 4px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);

    -webkit-animation: load8 1.1s infinite linear;

    animation: load8 1.1s infinite linear;

}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
