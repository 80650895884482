.main_container {
  width: 100%;
  height: max-content;
  padding: 181px 438px 223px 438px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  &__content {
    width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

h1 {
  font-family: var(--poppins-poppins-bold);
  font-size: 26px;
  line-height: 37px;
  margin-bottom: 32px !important;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 564px;
}

.tooltip {
  margin-top: -15px;
  font-size: 12px;
  font-family: var(--poppins-regular);
  color: #626262;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  color: #C7BCCE;
  cursor: pointer;

  &__back {
    display: flex;
    align-items: center;
    gap: 12px;

    font-weight: 500;
  }

  &__next {
    padding: 10px 20px;
    width: max-content;
    background: var(--type-purpure100-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;

    color: #FFFFFF;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
  }

  &__next_disable {
    padding: 10px 20px;
    width: max-content;
    background: #C7BCCE;
    border: none;
    border-radius: 50px;

    color: #FFFFFF;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
  }
}