@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  width: 100%;

  h4 {
    color: var(--btn-violet-primary);
  }

  &__form {
    width: 45%;

    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 18px;

    &__two_fields {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }

  &__back {
    @include body3;
    width: 45%;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--btn-violet-primary);
    cursor: pointer;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  &__btn {
    @include body5;
    width: 20%;
    padding: 16px;
    border-radius: 50px;
    background-color: var(--btn-violet-primary);
    color: var(--base-white);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    position: relative;

    &__disabled {
      @extend .main_container__btn;
      background-color: var(--btn-violet-disabled);
      cursor: default;

      &::before {
        display: none;
      }
    }
  }
}

.check_box {
  display: flex;
  gap: 15px;
  &__content {
    @include body6;

    & > span {
      padding-left: 0 !important;
      font: 600 14px/20px var(--poppins-poppins-semiBold) !important;
      text-decoration: underline !important;
      color: var(--btn-violet-primary) !important;
    }
  }
}
.password_ar {
  direction: rtl;
}
.icon_ar {
  left: 8px;
  right: inherit;
}

.two_fields_ar {
  flex-direction: row-reverse;
}

.check_box_ar {
  flex-direction: row-reverse;
}

.error_ar {
  direction: rtl;
}

.text_field_ar {
  direction: rtl;

  & > div > div > div {
    & > svg {
      margin: 0 !important;
    }
  }
}

.text_field_en {
  direction: ltr;
}

@media (max-width: 1000px) {
  .main_container {
    &__form {
      width: 60%;
    }

    &__back {
      width: 60%;
    }

    &__btn {
      width: 30%;
    }
  }
}

@media (max-width: 700px) {
  .main_container {
    &__form {
      width: 90%;
    }

    &__back {
      width: 90%;
    }

    &__btn {
      width: 45%;
    }
  }
}

@media (max-width: 400px) {
  .main_container__btn {
    width: 70%;
  }
}
