.main_container {
  background: #FFFFFF;
  width: 95%;
  overflow: hidden;
  height: fit-content;
  padding: 25px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.embed {
  width: 92vw;
  height: 80vh;
}