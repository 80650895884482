@import '../../../theme/scss/mixins.scss';

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}
.main_container {
  width: 500px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > svg {
        cursor: pointer;
      }
    }

    h4 {
      margin-bottom: 32px !important;
    }
  }
}

.loader {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.5);
  backdrop-filter: blur(3px);
}

.headline {
  @include body2;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #c7bcce;
  cursor: pointer;

  &__back {
    display: flex;
    align-items: center;
    gap: 12px;

    color: #8450a0;
    font-weight: 500;
  }

  &__submit {
    padding: 10px 20px;
    width: max-content;
    background: var(--type-purpure100-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;

    color: #ffffff;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
  }

  &__decline {
    width: max-content;
    background: none;
    border: none;
    cursor: pointer;

    color: var(--type-purpure100-color);
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
  }

  .action_btns {
    display: flex;
    gap: 8px;
  }
}

.child_info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  // margin-top: 24px;

  &__item {
    width: 48%;
    border-radius: 12px;
    padding: 12px;
    background: var(--base-100);
    display: flex;
    flex-direction: column;
    gap: 8px;

    & span {
      @include body6;
      color: var(--grey-primary);
    }

    & p {
      @include body2;
    }
  }

  &__item_reason {
    width: 100%;
    border-radius: 12px;
    padding: 12px;
    background: #f6f7fa;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.child_info_languages {
  display: flex;
  gap: 4px;
  line-height: 22px;
}

.answers {
  // overflow-y: scroll;
  height: 45vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 31px;
  padding-right: 15px;

  h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: var(--poppins-medium-500);
  }
}

// .answers::-webkit-scrollbar {
//   display: none;
// }

.text_container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.number {
  width: 32px;
  margin-top: 3px;
  color: var(--btn-violet-disabled);
}

.answer_text {
  @include body6;
  color: #626262;
  word-break: break-all;
}

.btn_decline_therapist {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btn_decline_therapist;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none;
  }

  &__cancel {
    @extend .btn_decline_therapist;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}
