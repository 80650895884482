@import '../theme/scss/mixins.scss';

.custom_link {
  &>a {
    color: var(--btn-violet-primary-focused) !important;
    text-decoration: underline !important;
  }
}

.status_container {
  width: max-content;
  height: 23px;

  padding: 4px 12px 4px 12px;
  border-radius: 50px;

  @include body5;
  display: flex;
  align-items: center;
}

.country_container {
  display: flex;
  align-items: center;
  gap: 5px;

  &>span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}