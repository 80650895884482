@import '../../../theme/scss/mixins';

.container {
    width: 100%;
    max-height: 300px;
}

.avatar_input {
    position: absolute;
    height: .4px;
    width: .4px;
}

.avatar_label {
    position: absolute;
    bottom: -15px;
    width: 29px;
    height: 29px;
    border: 1px solid var(--black100-color);
    border-radius: 50%;
    background-color: var(--btn-violet-primary);
    background-image: url('../../../theme/assets/icons/add_white.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.avatar {
    position: relative;
    margin: 10px auto 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatar_img {
        display: inline-block;
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

    .delete_avatar {
        position: absolute;
        right: calc(50% - 85px);
        top: 20px;
        width: 23px;
        height: 23px;
        background: url('../../../theme/assets/icons/delete_round.svg') no-repeat;
        cursor: pointer;

        &>svg>path {
            stroke: white;
        }
    }
}

.cropper {
    position: relative;
    width: 300px !important;
    height: 200px !important;
    margin: 10px auto 55px;

    &>.crop_btn {
        @include body2;
        position: absolute;
        bottom: -47px;
        right: 0;
        left: 0;
        margin: 0 auto;
        max-width: fit-content;
        padding: 8px 20px;
        display: block;
        border-radius: var(--input-border-radius);
        background-color: var(--btn-violet-primary);
        font-size: var(--main-fsize);
        line-height: 17px;
        color: var(--base-white);
        cursor: pointer;
    }
}