@import '../../../theme/scss/mixins.scss';

.main {
  position: absolute;
  top: 24%;
  left: 50%;
  transform: translate(-50%, 0);
}

.form_container {
  width: 440px;
  margin: 0 auto;
  padding: 64px 0 43px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.alt_login {
  @include body6;

  & span:first-child {
    @include link1;
    color: var(--btn-violet-primary);
  }
}

.login_btn {
  @include body2;
  width: 100%;
  margin: 20px 0;
  background-color: var(--btn-violet-primary);
}

.notification_container {
  display: flex;
  flex-direction: column;
  gap: 3px;

  & p {
    @include body6;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.input {
  display: flex;
  align-items: center;
}

.icon_container {
  position: relative;
  top: 18px;
  right: 25px;
  cursor: pointer;
}

.signIn_error {
  @include errorMessage;
  position: absolute;
  top: -9%;
}

.success_conatiner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > h4 {
      text-align: center;
    }

    & > p {
      @include body3;
      width: 493px;
      text-align: center;
    }
  }
}

@media (max-width: 600px) {
  .main {
    width: 100%;
  }
  .form_container {
    width: 100%;
    padding: 16px;
  }
  .header {
    align-items: center;
  }
  .alt_login {
    text-align: center;
  }
}
