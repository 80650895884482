@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';


.main_container {
  background: #FFFFFF;
  max-width: 80%;
  padding: 24px;
  position: relative;
  border-radius: 16px;
  top: 50%;
  left: 0;
  transform: translate(10%, -50%);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  pointer-events: all;

  &__note_position {
    position: absolute;
    top: -50px;
    background-color: var(--base-white);
    border-radius: 8px;
    padding: 5px 10px;
    @include body5;
    color: var(--btn-violet-primary-focused);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    &>svg {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 90%;
    overflow-y: scroll;

    &__info_block {
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      border-left: 2px solid var(--btn-violet-primary);
      gap: 8px;

      &__session_details {
        display: flex;
        align-items: center;
        gap: 5px;

        &>p {
          @include body2;
        }
  
        &>span {
          @include body3;
          color: var(--btn-violet-primary);
        }
      }
    }

    &__therapist_block {
      height: 80%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
          display: none;
      }
    }

    &__btn {
      @include body6;
      width: fit-content;
      padding: 8px;
      background-color: var(--btn-violet-primary);
      color: var(--base-100);
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;


  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &>div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .save {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 8px;
      width: max-content;

      &>svg {
        width: 20px;
        height: 20px;
      }

      &__error {
        &>path {
          fill: #d75221
        }
      }

      &>p {
        @include body5;
      }
    }
  }
}

.textarea_wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  &>textarea {
    resize: none;
    border-color: var(--btn-violet-primary);

    &::-webkit-scrollbar {
        display: none;
    }
  }

  &>img {
    top: 33px;
    right: 5px;
  }
}

.error {
  left: auto;
  right: 8px;
  bottom: -22px;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 22;
  background-color: rgba(0, 0, 0, 0.111);
  top: 0;
  left: 0;
  border-radius: 16px;
}