@import '../../../../theme/scss/mixins';

.submit_btn {
    @include submitBtn;
    display: initial;
    width: auto;
    background-color: var(--btn-violet-primary);
}

.submit_btn_notActive {
    @extend .submit_btn;
    display: initial;
    background: var(--btn-violet-disabled) !important;
    cursor: inherit;
}