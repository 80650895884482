@import '../../../../theme/scss/mixins.scss';

.messagesHistory {
  position: relative;
  background-color: var(--purple100-background);
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 0;

  &__messagesBlock {
    flex-shrink: 1;
    height: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    padding: 0 24px;
    margin-bottom: 24px;
    padding-top: 100px;

    &__dayContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__date {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          padding: 2px 8px;
          background: var(--gray300-background);
          border-radius: 999px;
          font-family: Poppins_Medium_500, sans-serif;
          font-size: 14px;
          line-height: 140%;
          color: white;
        }
      }

      &__doctor {
        padding-left: 64px;

        p {
          font-family: Poppins_Medium_500, sans-serif;
          font-size: 14px;
          line-height: 140%;
          color: var(--type-purpure200-color);
        }
      }

      &__messagesContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}

.listVisibility {
  display: none
}

.chat_header {
  @include body2;
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--purple200-background);
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 15px;

  &__photo {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: var(--purple300-backgrounde);
    display: flex;
    align-items: center;
    justify-content: center;

    &>img {
      width: inherit;
      height: inherit;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}

.header_content {
  display: flex;
  flex-direction: column;
  gap: 3px;

  &>p {
    width: max-content;
    @include body5;
  }
}

@media screen and (max-width: 1024px) {
  .listVisibility {
    display: block
  }
}