@import '../../../theme/scss/mixins';

.password {
  position: absolute;
  top: 24%;
  left: 50%;
  transform: translate(-50%, 0);
}

h3 {
  text-align: left !important;
}

.password_container {
  max-width: 440px;
  margin: 0 auto;
  padding: 43px 0;
}

.password_caption {
  @include caption;
  margin-bottom: 8px;
}

.password_error {
  @include errorMessage;
}

.form_item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.label {
  margin-bottom: 8px;
  font-size: var(--main-fsize);
  line-height: 22px;
}

.input {
  padding: 10px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  outline: none;
  font-size: var(--title-fsize);
}

.input::placeholder {
  font-size: var(--main-fsize);
  color: var(--secondary-text-color);
}

.error {
  @include errorField;
}

.confirm_msg {
  margin-top: 12px;
  margin-bottom: 32px;
  text-align: left;
  font-size: var(--main-fsize);
  line-height: 22px;
}

.verify_email {
  color: rgba(38, 38, 38, 0.7);
}

.clarification {
  text-align: center;
  font-size: 14px;
  line-height: 22px;

  & span:first-child {
    display: inline-block;
    margin-right: 5px;
  }
}

.send_btn {
  @include authBtn;
  padding: 0;
  color: var(--btn-violet-primary);
}

.send_btn_disabled {
  @extend .send_btn;
  color: var(--btn-violet-disabled);
  cursor: auto;
}

.error_icon {
  @include errorIcon;
}

.submit_btn {
  width: 100%;
  margin: 32px 0 24px;
  @include body2;
}

.loader,
.loader:after {
  margin: 32px auto 24px;
}

@media (max-width: 600px) {
  .password {
    width: 100%;
  }
  .password_container {
    width: 100%;
    padding: 0 16px;
  }
  h4 {
    text-align: center;
  }
  .confirm_msg {
    text-align: center;
  }
}
