@import '../../../theme/scss/mixins';

.confirm_email {
    width: 100%;
    height: 100%;
}

.confirm_email_container {
    max-width: 460px;
    margin: 0 auto;
    padding: 240px 0;
}

.confirm_email_caption {
    @include caption;
    margin-bottom: 32px;
}

.confirm_email_msg {
    text-align: center;
    font-size: var(--main-fsize);
    line-height: 22px;
}

.submit_btn {
    @include submitBtn;
    margin: 32px 0 24px;
}

.success_message {
    @include successMessage;
}

.clarification {
    text-align: center;
    font-size: 14px;
    line-height: 22px;

    & span:first-child {
        display: inline-block;
        margin-right: 5px;
    }
}

.login_btn {
    @include authBtn;
}

.loader,
.loader:after {
    width: 100px;
    height: 100px;
    margin: 100px auto 0;
}
