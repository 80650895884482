@import '../../../theme/scss/mixins';

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.headline {
  width: 560px;
  text-align: left;
}

.block {
  width: 560px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 50%;
  overflow-y: scroll;
  padding-right: 10px;
  padding-bottom: 20px;

  &__header > p {
    @include body6;
    color: var(--text-primary);
  }

  &__dropzone {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & ul {
      list-style: inside !important;
    }

    & li {
      @include body6;
      color: var(--grey-primary);
    }
  }
}

.form_item {
  width: 100%;
}

.form_field_country {
  width: 100%;

  & > div {
    width: 100%;

    & > img + input {
      padding: 8px 12px 8px 40px !important;
    }

    input {
      width: 100%;
    }
  }
}

.error_wraper {
  width: max-content;
}

.with_custom {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.two_fields_container {
  width: 100%;
  display: flex;
  gap: 16px;

  &__multiselector {
    width: 50%;
  }

  &__input {
    display: flex;
    position: relative;

    &__icon {
      position: absolute;
      padding: 3px;
      top: 45%;
      right: 10px;
      width: 25px;
      height: 25px;
      cursor: pointer;

      & > path {
        stroke: var(--text-primary);
      }
    }

    &__add_icon {
      position: absolute;
      padding: 3px;
      top: 55%;
      right: 10px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: pointer;
    }

    &__add_icon:hover {
      background-color: var(--btn-violet-secondary);
    }
  }
}

.calendar_container {
  position: relative;

  &__calendar {
    width: fit-content;
    height: fit-content;
    background-color: var(--base-white);
    position: absolute;
    z-index: 2;
    top: 100px;
    right: 0;
    border-radius: 12px;
    border: 1px solid var(--btn-violet-secondary);
  }
}

.footer {
  width: 560px;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.inner_container {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 70px;
}

.text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paragraph {
  color: #171717;
  font: 500 14px/20px var(--poppins-medium-500);
}

.description {
  color: #9b9b9b;
  font: 400 12px/17px var(--poppins-regular);
}

.file_container {
  background-color: #f6fafe;
  border-radius: 999px;
  border: 1px solid var(--btn-violet-secondary);
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 20px;
  padding: 5px;
  margin-bottom: 8px;
  position: relative;

  & > svg {
    width: 16px;
    height: 16px;
  }

  & > p {
    @include body6;
    color: var(--btn-violet-primary);
    max-width: 60%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > span {
    @include subtext3;
    color: var(--text-secondary);
  }

  &__delete {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    border: 1px solid #eee;
    background-color: var(--base-white);
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 15px;
    cursor: pointer;
  }
}

.purpleBtn {
  @include body2;
  color: var(--base-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
  border-radius: 50px;
  background-color: var(--btn-violet-primary);
  cursor: pointer;
}

.purpleBtn_disabled {
  @extend .purpleBtn;
  background-color: var(--btn-violet-disabled) !important;
  cursor: default;
}

.pinkBtn {
  @include subtext2;
  color: var(--btn-pink-primary);
  border: 1px solid var(--btn-pink-primary);
  border-radius: 50px;
  padding: 7px 12px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.noBorderBtn {
  @include body2;
  color: var(--btn-violet-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
}

.noBorderBtn_disabled {
  @extend .noBorderBtn;
  color: var(--btn-violet-disabled) !important;
  cursor: default !important;

  & > svg > path {
    stroke: var(--btn-violet-disabled) !important;
  }
}

.stepper {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .main_container {
    width: 100%;
  }

  .block {
    width: 100%;
    padding: 16px;
  }

  .headline {
    width: 100%;
    text-align: center;
  }

  h6 {
    text-align: center;
  }

  .footer {
    width: 100%;
    margin-top: 0;
    padding: 16px;
  }
}
