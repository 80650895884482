@import '../../../theme/scss/mixins';

.password {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, 0);
}

.password_container {
    max-width: 440px;
    margin: 0 auto;
    padding: 43px 0;
}

.password_caption {
    @include caption;
    margin-bottom: 32px;
}

.form_item {
    @include formItem;
}

.input {
    padding: 10px 12px;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    outline: none;
    font-size: var(--title-fsize);
}

.input::placeholder {
    font-size: var(--main-fsize);
    color: var(--secondary-text-color);
}

.submit_btn {
    width: 100%;
    margin: 32px 0 24px;
}

.loader {
    margin: 32px auto 24px;
}

.success_message {
    @include successMessage;
}

.clarification {
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    & span:first-child {
        display: inline-block;
        margin-right: 5px;
    }
}

.login_btn {
    @include authBtn;
}

