@import '../../theme/scss/mixins';

.user_menu {
    position: relative;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
}

.dropdown_btn {
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
}

.btn_title {
    margin-right: 9px;
    font-size: var(--title-fsize);
    line-height: 24px;
    white-space: nowrap;
    color: var(--black600-color);
}

.btn_arrow,
.btn_arrow_open {
    @include dropdownArrow;
}

.btn_arrow_open {
    transform: rotate(180deg);
}

.dropdown_list,
.dropdown_list_open {
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 10;
    width: fit-content;
    max-height: 0;
    padding: 0;
    border: none;
    border-radius: var(--main-border-radius);
    background: var(--white-color);
    overflow: hidden;
    transition: all .3s ease;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: var(--main-border-radius);
    }
}

.dropdown_list_open {
    max-height: fit-content;
    box-shadow: 1px 1px 1px var(--black300-color), -1px -1px 1px var(--black300-color), -1px 1px 1px var(--black300-color), 1px -1px 1px var(--black300-color);

    & .list_item:last-child {
        border-bottom: none;
    }
}

.list_item {
    display: flex;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 12px 10px;
    border-bottom: 1px solid var(--black200-color);
    font-size: var(--title-fsize);
    line-height: 24px;
    white-space: nowrap;
    color: var(--black600-color);
    cursor: pointer;

    & span {
        display: inline-block;
        color: var(--black600-color);
    }

    & > img {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    &:hover {
        background: var(--black200-color);
        color: var(--black900-color);

        & span {
            color: var(--black900-color);
        }
    }
}
