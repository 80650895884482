@import '../../theme/scss/mixins.scss';

.main_container {
  // padding: 24px 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.data_container {
  min-height: 70vh;
  overflow-x: scroll;
}

.header {
  display: flex;
  flex-direction: column;

  &__first_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__search {
    display: flex;
    gap: 24px;
    align-items: flex-end;
    margin-bottom: 10px;

    &__container {
      width: 260px;
      box-sizing: border-box;
      height: 40px;
      background-color: var(--base-white);
      border-radius: 8px;
      border: 1px solid var(--base-50);
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      & > div {
        & > input {
          @include body6;
          color: var(--text-gray);
        }
      }

      &__text {
        @include body6;
        color: var(--text-gray);
      }
    }
  }
}

.tabs {
  margin-top: 12px;
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  @include body3;
  color: var(--grey-primary);

  & > p {
    cursor: pointer;
  }

  &__selected_tab {
    @include body2;
    color: var(--text-primary);
    border-bottom: 2px solid var(--btn-pink-primary);
  }
}
