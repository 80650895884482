@import '../../../../theme/scss/mixins.scss';

.main_container {
  background: #FFFFFF;
  width: 670px;
  padding: 25px;
  height: 426px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &>span {
    @include body5;
    color: var(--grey-primary);
  }

  &__advisor_container {
    display: flex;
    gap: 12px;
    align-items: center;

    &__text {
      display: flex;
      flex-direction: column;

      &>p {
        @include body5
      }

      &>span {
        @include body6;
        color: var(--grey-primary)
      }
    }
  }

  &__parents {
    width: 100%;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 13px 0;
      border-bottom: 1px solid var(--grey-secondary);

      &__parent_container {
        display: flex;
        gap: 12px;
        align-items: center;

        &__text {
          display: flex;
          flex-direction: column;

          &>p {
            @include body5
          }

          &>span {
            @include body6;
            color: var(--grey-primary)
          }
        }
      }

      &__children {
        display: flex;
        gap: 10px;
        margin-right: 24px;
      }
    }
  }
}

.user_container {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: var(--btn-violet-secondary);
  color: var(--btn-violet-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    width: inherit;
    height: inherit;
    border-radius: 6px;
    object-fit: cover;
  }
}

.click:hover {
  cursor: pointer;
}

.no_data {
  @include body6;
  color: var(--grey-primary);
}