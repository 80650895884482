@import '../../../theme/scss/mixins';

.mainContainer {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__content {
        min-width: 50%;
        padding: 308px 142px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        justify-content: center;
        height: 100%;

        &__text {
            width: 500px;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &>h4 {
                width: 100%;
            }

            &__email {
                max-width: 100%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: var(--btn-violet-primary);
            }
        }

        &__btn_container {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 436px;

            &__btn {
                @include body2;
                padding: 10px 20px;
                width: max-content;
                background-color: var(--btn-violet-primary);
                color: var(--base-white);
                border-radius: 50px;
                cursor: pointer;
            }
        }

    }

    &__pic_container {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        overflow: hidden;

        &>img {
            // width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}