.sentMessageInstance {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;

  &__content {
    display: flex;
    align-items: center;
    gap: 16px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__message {
      background-color: var(--purple300-backgrounde);
      padding: 16px;
      border-radius: 12px;
      line-height: 140%;
      color: var(--text-primary-light);
      font-family: Poppins_Regular, sans-serif;

      &>span {
        word-break: break-all;
      }
    }
  }

  &__time {
    color: var(--text-secondary);
    font-size: 12px;
    font-family: Poppins_Regular, sans-serif;
  }
}

.preview__item__preview__imgWrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: var(--purple200-background);

  &>img {
    width: 40px;
    height: 40px;
  }

  img,
  video,
  svg {
    display: block;
    height: 48px;
  }

  svg {
    path {
      stroke: var(--type-purpure100-color) !important;
    }
  }

  &>img {
    width: 70px;
    height: 70px;
    border-radius: 2px;
    object-fit: fill;
  }
}