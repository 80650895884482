@import '../../../../theme/scss/mixins';

.submit_btn {
  @include submitBtn;
  display: initial;
  width: auto;
  border: 1px solid var(--primary900-color);
}

.submit_btn:hover {
  background: var(--primary800-color);
  border: 1px solid var(--primary800-color);
}
