.details_nav {
  display: none;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .details_nav {
    display: block;
    position: absolute;
    width: 100%;
    height: 70px;
    padding-inline: 16px;
    z-index: 50;
  }
  .details_content {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .details_back {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .back {
    margin-left: 12px;
    color: var(--btn-violet-primary);
  }
  .info {
    color: var(--text-primary);
    font-weight: 500;
  }
}

@media (max-width: 600px) {
  .details_nav {
    display: block;
    position: absolute;
    width: 100%;
    height: 70px;
    padding-inline: 16px;
    z-index: 50;
  }
  .details_content {
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .details_back {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .back {
    margin-left: 12px;
    color: var(--btn-violet-primary);
  }
  .info {
    color: var(--text-primary);
    font-weight: 500;
  }
}
