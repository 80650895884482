@import '../../../../theme/scss/mixins.scss';

.main_container {
  position: absolute;
  background: #FFFFFF;
  width: 860px;
  max-height: 95%;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.user_details {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px 0 10px 0;

  &__photo {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: var(--btn-violet-secondary);
    color: var(--btn-violet-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &>p {
      @include body5;
    }

    &__changed_price {
      display: flex;
      gap: 12px;

      &>p {
        @include body6;
      }

      &>span {
        @include body6;
        text-decoration: line-through;

        color: var(--grey-primary);
      }
    }


  }
}

.price_container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    gap: 16px;

    &>p {
      @include body6;
    }

    &>span {
      @include body5;
      color: var(--grey-primary);
    }

    &__final {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      &>p {
        font: 600 20px/24px var(--poppins-regular);
        color: var(--btn-violet-primary)
      }

      &>span {
        font: 600 20px/24px var(--poppins-regular);
        color: var(--grey-primary);
      }
    }
  }
}

.options_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 20px;

  &__item {
    width: 30%;
    border-radius: 12px;
    background-color: var(--btn-violet-secondary);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 300px;
    cursor: pointer;

    &>div {
      padding-top: 25px;
      height: 40%;
      color: var(--grey-primary);
      font-size: 50px;


      &>svg {
        width: 60px;
        height: 60px;

        fill: var(--grey-primary);

        & path {
          fill: var(--grey-primary);
          stroke: var(--grey-primary)
        }
      }
    }

    &>h5 {
      color: var(--grey-primary);
    }
  }

  &__item_active {
    width: 30%;
    border-radius: 12px;
    background-color: #e4d5f5;
    box-shadow: var(--btn-violet-primary) 0px 7px 15px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 300px;
    cursor: pointer;

    &>div {
      padding-top: 25px;
      color: var(--btn-violet-primary);
      font-size: 50px;
    }

    &>h5 {
      color: var(--grey-primary);
    }
  }

  &__item_blured {
    width: 30%;
    border-radius: 12px;
    background-color: var(--btn-violet-secondary);
    filter: blur(0.7px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    min-height: 300px;
    cursor: pointer;

    &>div {
      padding-top: 25px;
      color: var(--grey-primary);
      font-size: 50px;

      &>svg {
        width: 60px;
        height: 60px;
        fill: var(--grey-primary);

        &>path {
          fill: var(--grey-primary)
        }
      }
    }

    &>h5 {
      color: var(--grey-primary);
    }
  }
}

.field_wrapper {
  position: relative;
  border-bottom: 1px solid var(--grey-primary);


  & input {
    color: var(--btn-violet-primary);
    font-size: 50px;
    padding: 0;
    text-align: center;
  }
}

.adornment {
  & p {
    color: var(--btn-violet-primary) !important;
    font: 400 50px var(--poppins-regular);
  }
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  &__reset {
    @include button1;
    padding: 10px 20px;
    border-radius: 50px;
    border: 1px solid var(--btn-pink-primary);
    color: var(--btn-pink-primary);
    cursor: pointer;
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 16px;

    &__cancel {
      @include button1;
      color: var(--btn-violet-primary);
      cursor: pointer;
    }

    &__apply {
      @include button1;
      padding: 10px 20px;
      border-radius: 50px;
      background-color: var(--btn-violet-primary);
      color: var(--base-white);
      cursor: pointer;
    }

    &__apply_disabled {
      @include button1;
      padding: 10px 20px;
      border-radius: 50px;
      background-color: var(--btn-violet-disabled);
      color: var(--base-white);
    }
  }
}

.loading_conatiner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}