@import '../../../theme/scss/mixins.scss';

.main_container {
  // padding: 24px 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.data_container {
  min-height: 70vh;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;

  &__first_container {
    display: flex;
    justify-content: space-between;
  }

  &__search {
    display: flex;
    gap: 24px;

    &__container {
      width: 260px;
      box-sizing: border-box;
      height: 40px;
      background-color: var(--base-white);
      border-radius: 8px;
      border: 1px solid var(--base-50);
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      & > div {
        & > input {
          @include body6;
          color: var(--text-gray);
        }
      }

      &__text {
        @include body6;
        color: var(--text-gray);
      }
    }
  }
}

.popup_text {
  @include body6;
}

.tabs {
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  margin-top: 12px;
  @include body3;
  color: var(--grey-primary);

  & > p {
    cursor: pointer;
  }

  &__selected_tab {
    @include body2;
    color: var(--text-primary);
    border-bottom: 2px solid var(--btn-pink-primary);
  }
}

.btn_decline_application {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btn_decline_application;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none;
  }

  &__cancel {
    @extend .btn_decline_application;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}
