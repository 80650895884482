.main_container {
  background: #ffffff;
  width: 860px;
  max-height: 95%;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.thanks_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  margin-top: 32px;
  margin-bottom: 64px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.thanks_title {
  text-align: center;
  font-family: var(--poppins-poppins-semiBold);
  font-size: 26px;
  line-height: 36px;
}

.thanks_desc {
  display: inline;
  font-family: var(--poppins-regular);
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  &>p {
    font-family: var(--poppins-poppins-semiBold);
    display: inline;
    color: var(--btn-violet-primary);
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .modal {
    margin-inline: 40px;
  }
  .main_container {
    width: 100%;
    max-width: 768px;
    height: 496px;
    outline: none;
  }
}

@media (max-width: 600px) {
  .modal {
    margin-inline: 16px;
  }
  .main_container {
    width: 100%;
    max-width: 450px;
    height: 520px;
    outline: none;
  }
  .thanks_section {
    margin-top: 0;
  }
}

@media (max-width: 420px) {
  .main_container {
    height: 496px;
  }
}
