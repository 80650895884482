// Advisor_assign_therapist
.main_container {
  padding: 30px;
  display: flex;
  gap: 20px;
}

.general_block {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__info {
    width: 100%;
    height: fit-content;
    // height: max-content;
    overflow: scroll;
    background-color: #ffffff;
  }

  &__meeting_block {
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.questionnaire_block {
  width: 100%;
  height: 40vh;
}

.therapists_block {
  width: 50%;
}

@media (max-width: 1000px) {
  .main_container {
    flex-direction: column;
  }

  .general_block {
    width: 100%;

    &__info {
      height: max-content;
    }
  }

  .therapists_block {
    width: 100%;
  }

  .questionnaire_block {
    height: 80vh;
  }
}

// Advisor_parent_profile
.main_container {
  padding: 30px;
  display: flex;
  gap: 20px;
  height: 100%;
}

.general_block {
  width: 50%;
}

@media (max-width: 1000px) {
  .main_container {
    flex-direction: column;
  }

  .general_block {
    width: 100%;

    &__info {
      height: max-content;
    }
  }
}
