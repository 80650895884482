@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  background-color: var(--base-white);
  border-radius: 16px;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--btn-violet-secondary);
      color: var(--btn-violet-primary);
      padding: 16px;
      border-radius: 8px;
    }
  }

  &__profile_info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--btn-violet-secondary);

    &__container {
      width: 100%;
      display: flex;
      align-items: center;

      & div {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 16px;

        & > p {
          @include body6;
          color: var(--grey-primary);
        }

        & > span {
          @include body5;
        }
      }
    }
  }

  &__client_info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--btn-violet-secondary);

    &__container {
      width: 100%;
      display: flex;
      align-items: center;

      & div {
        width: 50%;
        display: flex;
        align-items: center;
        gap: 16px;

        & > p {
          @include body6;
          color: var(--grey-primary);
        }

        & > span {
          @include body5;
        }
      }
    }
  }

  &__advisor_info {
    display: flex;
    align-items: center;
    gap: 16px;

    &__initials {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      background-color: var(--btn-violet-secondary);
      color: var(--btn-violet-primary);
      border-radius: 4px;
    }

    & > div {
      display: flex;
      flex-direction: column;

      & > p {
        @include body3;
      }
      & > span {
        @include body6;
        color: var(--grey-primary);
      }
    }
  }
}
