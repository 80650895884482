@import '../../../theme/scss/mixins.scss';

.main_container {
  display: flex;
  align-items: center;
  gap: 8px;

  &>p {
    color: #3B8400;
    @include body3;
  }
  
  &__time_container {
    display: flex;
    gap: 8px;
    
    &>span {
      color: #3B8400 !important;
    }
  }
}

@media (max-width: 720px){
  .main_container {
    &>p {
      display: none;
    }
  }
}