@import '../../../theme/scss/mixins';

.signup_form {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, 0);
}

.form_container {
    width: 440px;
    margin: 0 auto;
    padding: 64px 0 43px 0;
}

.signup_caption {
    margin-bottom: 32px !important;
}

.signup_error {
    @include errorMessage;
    position: absolute;
    top: -11%;
}

.form_item {
    @include formItem;
}

.message_wrap {
    display: flex;
    justify-content: flex-end;
}

.message {
    border: none;
    outline: none;
    font-family: Popping_Regular, sans-serif;
    font-size: var(--main-fsize);
    background: none;
    color: var(--main-text-color);
    cursor: pointer;
}

.signup_btn {
    @include button1;
    height: 42px;
    // background-color: var(--btn-violet-primary);
    width: 100%;
    margin: 36px 0 0 0;
}

.loader {
    margin: 16px auto 0;
}