@import '../../../../theme/scss/mixins.scss';

.main_container {
  width: 100%;
  border: 1px solid #f6f7fa;
  border-radius: 8px;
  background: #fdfcff;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;

  padding-bottom: 16px;
  border-bottom: 1px solid #eee9f3;

  &__record_info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-family: var(--poppins-regular);
      font-size: 14px;
      line-height: 20px;
      color: #626262;
    }

    .date_and_time {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }

    .date {
      font-family: var(--poppins-medium-500);
      color: #171717;
      font-size: 16px;
      line-height: 23px;
    }

    .time {
      font-family: var(--poppins-regular);
      color: #171717;
      font-size: 16px;
      line-height: 23px;
    }
  }

  .status_container {
    @include subtext2;
    padding: 4px 12px;
    border-radius: 50px;
    width: max-content;
    height: fit-content;
    position: absolute;
    // right: 0;
    top: -5px;
  }
}

.content {
  display: flex;
  justify-content: space-between;

  .therapist_info {
    display: flex;
    align-items: center;
    gap: 16px;

    &__photo {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 6px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .name {
      font-family: var(--poppins-medium-500);
      font-size: 16px;
      line-height: 23px;
    }

    .occupation {
      font-family: var(--poppins-regular);
      font-size: 14px;
      line-height: 20px;
      color: #626262;
    }
  }

  .btn {
    padding: 10px 20px;
    border: 1px solid #d89b95;
    border-radius: 50px;
    background: none;
    color: #d89b95;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }

  .therapist_btn_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .new_note {
    width: 45%;
    padding: 10px 20px;
    border: 1px solid #d89b95;
    border-radius: 50px;
    background: none;
    color: #d89b95;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }

  .join {
    width: 45%;
    border: none;
    border-radius: 50px;
    background: #8450a0;
    color: #ffffff;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    cursor: pointer;
  }

  .notes {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #d89b95;
    border-radius: 50px;
    background: none;
    color: #d89b95;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }
}

.btns_container {
  display: flex;
  gap: 16px;
}

.pink_btn {
  @include body2;
  padding: 10px 20px;
  border: 1px solid var(--btn-pink-primary);
  color: var(--btn-pink-primary);
  border-radius: 50px;
  cursor: pointer;
  min-width: max-content;
}

.violet_btn {
  @include body2;
  padding: 10px 20px;
  background-color: var(--btn-violet-primary);
  color: var(--base-white);
  border-radius: 50px;
  cursor: pointer;
  min-width: max-content;
}

.violet_btn_disabled {
  @include body2;
  padding: 10px 20px;
  background-color: var(--btn-violet-disabled);
  color: var(--base-white);
  border-radius: 50px;
  text-align: center;
  min-width: max-content;
}

.green_btn {
  @include body2;
  padding: 10px 20px;
  background-color: none;
  color: #3b8400;
  border-radius: 50px;
  border: 2px solid #3b8400;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 120px;
  min-width: max-content;
}

.green_btn_disabled {
  @include body2;
  padding: 10px 20px;
  color: #3b84005e;
  border: 2px solid #3b84005e;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 120px;
  min-width: max-content;
}

.session_note {
  & > p {
    @include body5;
    color: var(--btn-violet-primary);
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .content {
    display: block;
  }
  .therapist_info {
    padding-bottom: 16px;
  }

  .violet_btn {
    max-width: 250px;
    text-align: center;
    padding: 10px 20px;
  }

  .date_and_time {
    flex-direction: column;
    align-items: flex-start !important;
  }
}
