@import '../../theme/scss/mixins.scss';
@import '../../theme/scss/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;1,400;1,500;1,600&display=swap');

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    gap: 30px;

    &__btn {
      width: 50%;
      @include body1;
      border-radius: 5px;
      background-color: var(--btn-violet-primary);
      color: var(--base-white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0;
      font-size: 23px;
      cursor: pointer;
    }

    &__disclamer {
      width: 50%;
      margin-top: -20px;
      font: italic 400 10px/16px 'Poppins', sans-serif;
      color: #707070;

      & > span {
        font: italic 600 10px/16px 'Poppins', sans-serif;
      }
    }

    & > h6 {
      width: 60%;
      color: #707070;
      text-align: center;
    }
  }

  &__content {
    max-height: 45%;
    overflow: scroll;
    width: 40%;
    margin-top: 30px;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 25px;

    & > h5 {
      text-align: center;
      color: var(--btn-violet-primary);
    }

    & > p {
      @include body1;
      text-align: center;
      color: var(--text-primary);
    }

    & > span {
      @include body3;
      text-align: center;
      color: var(--text-secondary);
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > h6 {
        color: var(--btn-violet-primary);
      }

      & > p {
        @include body3;
        font-size: 18px;
        color: #707070;
        padding-left: 16px;
      }
    }
  }

  &__footer {
    width: 40%;
    display: flex;
    align-items: center;
    gap: 12px;
    @include body3;
    cursor: pointer;
    margin-top: 25px;

    color: var(--btn-violet-primary);
    & > svg {
      width: 20px;
      height: 20px;

      & > path {
        stroke-width: 2;
        stroke: var(--btn-violet-primary);
      }
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  gap: 25px;

  align-items: center;
  justify-content: center;

  & > h5 {
    color: var(--btn-violet-primary);
  }
}

@media (max-width: 900px) {
  .main_container {
    &__header {
      &__btn {
        width: 70%;
      }

      &__disclamer {
        width: 70%;
      }

      & > h6 {
        width: 80%;
      }
    }

    &__content {
      width: 50%;
      box-sizing: border-box;
    }

    &__footer {
      width: 70%;
    }
  }
}

@media (max-width: 650px) {
  .main_container {
    &__header {
      width: 90%;

      &__btn {
        width: 90%;
      }

      &__disclamer {
        width: 90%;
      }

      & > h6 {
        width: 90%;
      }
    }

    &__content {
      width: 90%;
    }

    &__footer {
      width: 90%;
    }
  }
}

@media (max-width: 400px) {
  .loading {
    & > h5 {
      width: 90%;
      text-align: center;
    }

    & > svg {
      width: 90%;
    }
  }
}
