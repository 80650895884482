@import '../../../../theme/scss/mixins.scss';

.main_container {
  background: #FFFFFF;
  width: 436px;
  padding: 64px 24px 24px 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 40px;

  .icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__form {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & p {
        @include body6;
      }
    }
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btn {
  width: 100%;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;

  @include body2;
  cursor: pointer;

  &__btn_save {
    @extend .btn;
    background: var(--btn-violet-primary);
    color: var(--base-white)
  }

  &__btn_cancel {
    @extend .btn;
    background: none;
    border: 1px solid var(--btn-pink-primary);
    color: var(--btn-pink-primary)
  }
}

.btn_cancel {
  @include cancelBtn;
}

.form_item {
  @include formItem;
  border-radius: 12px;
  margin: 0;
}

input {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  border: 1px solid grey
}

.loading_conatiner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}