@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 40px;

  &__header {
    width: 60%;
    @include body3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    &__active {
      display: flex;
      align-items: center;
      gap: 12px;
      color: var(--btn-violet-primary);
      cursor: pointer;

      & > svg {
        width: 25px;
        height: 25px;

        & > path {
          stroke: var(--btn-violet-primary);
          stroke-width: 2;
        }
      }
    }

    &__disabled {
      @extend .main_container__header__active;
      color: var(--btn-violet-disabled);
      cursor: default;

      & > svg > path {
        stroke: var(--btn-violet-disabled);
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;

    height: 70%;
    width: 60%;
    max-width: 60%;

    & h4 {
      color: #707070;
      text-align: center;
    }

    &__item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 50px;
      align-items: center;

      &__btns_main {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        user-select: none;

        width: 100%;

        &__default {
          @include body3;
          width: 100%;
          height: 45px;
          border: 1px solid var(--btn-violet-primary);
          color: var(--btn-violet-primary);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          cursor: pointer;
        }

        &__active {
          @extend .main_container__content__item__btns_main__default;
          background-color: var(--btn-violet-primary);
          color: var(--base-white);

          &:hover {
            background-color: var(--btn-violet-primary) !important;
            color: var(--base-white) !important;
          }
        }
      }

      &__btns_sub {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        user-select: none;

        width: 70%;
        max-width: 70%;

        & div {
          width: 100%;
        }
      }
    }
  }
}

.progress {
  & > span {
    background-color: var(--btn-violet-secondary);

    & > span {
      background-color: var(--btn-violet-primary);
    }
  }
}

@media (pointer: fine) {
  .main_container__content__item__btns_main__default:hover {
    background-color: #9064aa84;
    border-color: #9064aa84;
    color: var(--base-white);
    transition: background-color 0.6s;
  }
}

@media (max-width: 700px) {
  .main_container {
    &__header {
      width: 90%;
    }

    &__content {
      width: 90%;
      max-width: 90%;
    }
  }
}

@media (max-width: 500px) {
  .main_container__content__item__btns_main {
    flex-direction: column;
  }
}
