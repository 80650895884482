@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 40px;

  &__header {
    max-width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    flex-direction: column;
    text-align: center;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      &>h6 {
        color: var(--btn-violet-primary)
      }

      &>div {
        @include body3;
        max-width: 70%;

        &>span {
          @include body1;
          color: var(--btn-violet-primary)
        }
      }
    }

    &>span {
      @include body6;
      color: var(--text-secondary);
    }
  }

  &__btns {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    user-select: none;

    &>div {
      @include body3;
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--grey-primary);
      cursor: pointer;

      &>svg {
        width: 20px;
        height: 20px;
        &>path {
          stroke: var(--grey-primary);
        }
      }
    }

    &>span {
      display: flex;
      align-items: center;

      color: var(--btn-violet-primary);

      cursor: pointer;

      &>svg {
        width: 20px;
        height: 20px;
    
        &>path {
          stroke: var(--btn-violet-primary);
          stroke-width: 2;
        }
      }
    }
  }

  &__footer {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;
  
      &>div {
        display: flex;
        align-items: center;
        gap: 12px;
        @include body3;
        cursor: pointer;
  
        color: var(--btn-violet-primary);
  
        &>svg {
          width: 20px;
          height: 20px;
  
          &>path {
            stroke-width: 2;
            stroke: var(--btn-violet-primary);
          }
        }
      }
    }
}

@media (max-width: 900px) {
  .main_container {
    &__header {
      max-width: 90%;
    }

    &__content {
      width: 90%;

      &__btns {
        width: 100%;
      }
    }

    &__btns {
      width: 60%;
    }

    &__footer {
      width: 90%;
    }
  }
}

@media (max-width: 600px) {
  .first_question_small_screen {
    justify-content: flex-start;
  }

  .main_container {
    padding: 30px 20px;

    &__header {
      width: 100%;

      &>div>div {
        max-width: 100%;
      }
    }

    &__btns {
      width: 70%;
      // flex-direction: column;
      // align-items: flex-start;
      // gap: 25px;
    }

    &__footer {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .main_container__btns {
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
  }
}