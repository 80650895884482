@import '../../../theme/scss/mixins';

.main_conatiner {
  padding: 24px 102px 24px 102px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    flex-direction: column;

    &__first_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    &__second_block {
      display: flex;
      justify-content: flex-end;
    }

    &__search {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 24px;

      &__container {
        width: 260px;
        box-sizing: border-box;
        height: 40px;
        background-color: var(--base-white);
        border-radius: 8px;
        border: 1px solid var(--base-50);
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;


        &>div {
          &>input {
            @include body6;
            color: var(--text-gray)
          }
        }

        &__text {
          @include body6;
          color: var(--text-gray);
        }
      }
    }

    &__btn {
      width: max-content;
      @include body2;
      color: var(--base-white);
      display: flex;
      gap: 16px;
      background-color: var(--btn-violet-primary);
      border-radius: 50px;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
    }
  }

}

.data_container {
  min-height: 70vh;
}

.btns {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btns;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none
  }

  &__cancel {
    @extend .btns;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}