@import '../../../theme/scss/variables.scss';
@import '../../../theme/scss/mixins.scss';

.main_container {
  background: #FFFFFF;
  width: 560px;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &>p {
    @include body2;
    color: var(--grey-primary);
    text-align: center;
    cursor: pointer;
  }
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}