@import '../../../theme/scss/mixins.scss';

.main_container {
  // padding: 24px 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  flex-direction: column;

  &__first_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__qualification_block {
    margin-top: 12px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;

    & > p {
      @include body5;
      height: fit-content;
    }

    &__qualification_hint {
      color: var(--grey-primary) !important;
    }

    &__sort {
      height: 40px;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 8px;
      background-color: var(--base-white);
      border: 1px solid var(--base-50);

      & > svg {
        cursor: pointer;
      }
    }
  }

  &__search {
    display: flex;
    gap: 24px;

    &__container {
      width: 260px;
      box-sizing: border-box;
      height: 40px;
      background-color: var(--base-white);
      border-radius: 8px;
      border: 1px solid var(--base-50);
      padding: 10px;
      display: flex;
      align-items: center;
      gap: 10px;

      & > div {
        & > input {
          @include body6;
          color: var(--text-gray);
        }
      }

      &__text {
        @include body6;
        color: var(--text-gray);
      }
    }

    &__btn {
      @include body2;
      color: var(--base-white);
      display: flex;
      gap: 16px;
      background-color: var(--btn-violet-primary);
      border-radius: 50px;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
    }
  }
}

.data_container {
  min-height: 70vh;
  overflow-x: scroll;
}

.btn_decline_therapist {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btn_decline_therapist;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none;
  }

  &__cancel {
    @extend .btn_decline_therapist;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}
