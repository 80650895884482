@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main {
  width: 33%;
  min-width: 200px;
  max-width: 300px;
  border-radius: 12px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  background: #fafafc;
  border: 1px solid #f6f7fa;
  padding: 16px;
}

.header {
  display: flex;
  position: relative;
  justify-content: center;

  &__photo {
    width: 80px;
    height: 80px;
    border-radius: 12px;
    background: #eee9f3;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 12px;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__name {
    word-break: normal;
    font-family: var(--poppins-medium-500);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  & > p {
    @include body5;
    color: var(--btn-violet-primary);
    cursor: pointer;
    width: fit-content;
  }

  span {
    margin-top: 20px;
    font-family: var(--poppins-medium-500);
    font-size: 14px;
    line-height: 20px;
    color: #626262;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.btns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: -10px;

  &__details {
    background: none;
    border: 1px solid #d89b95;
    border-radius: 50px;
    padding: 10px 20px;
    color: #d89b95;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }

  &__call {
    border: 1px solid;
    background: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
    cursor: pointer;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .accept_btn {
      @include body2;
      color: #3b8400;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #91c72d4e;
      cursor: pointer;
    }
    .decline_btn {
      @include body2;
      color: #e51616;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #ffe4e4;
      cursor: pointer;
    }
  }
}

.status {
  position: absolute;
  top: -10px;
  right: -15px;
}

.status_accepted {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 50px;
  background: #f8ffeb;
  color: #3b8400;
  font-family: var(--poppins-medium-500);
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: right;
}

.status_waiting {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border-radius: 50px;
  background: #fffbe4;
  color: #b19607;
  font-family: var(--poppins-medium-500);
  font-size: 12px !important;
  line-height: 15px !important;
  text-align: right;
}

.actions_container {
  margin-left: 20px;
  display: flex;
  gap: 8px;
}
