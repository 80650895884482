.recievedMessageInstance {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__message {
      display: flex;
      align-items: center;
      gap: 16px;

      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      &__text {
        background-color: var(--gray200-background);
        padding: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        line-height: 140%;
        border-radius: 12px;
        color: var(--text-primary-light);
        font-family: Poppins_Regular, sans-serif;

        & > span {
          word-break: break-all;
        }
      }
    }

    &__time {
      color: var(--text-secondary);
      font-size: 12px;
      font-family: Poppins_Regular, sans-serif;
    }
  }
}

.preview__item__preview__imgWrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  background-color: var(--purple200-background);

  & > img {
    width: 40px;
    height: 40px;
  }

  img,
  video,
  svg {
    display: block;
    height: 48px;
  }

  svg {
    path {
      stroke: var(--type-purpure100-color) !important;
    }
  }

  & > img {
    width: 70px;
    height: 70px;
    border-radius: 2px;
    object-fit: fill;
  }
}
