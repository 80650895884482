@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  background: #ffffff;
  max-width: 80%;
  min-width: 300px;
  padding: 24px;
  position: relative;
  border-radius: 16px;
  top: 50%;
  left: 0;
  transform: translate(10%, -50%);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  pointer-events: all;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 90%;
    overflow-y: scroll;
    min-height: 80%;

    &__info_block {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      gap: 8px;

      & > p {
        @include body6;
        max-width: 95%;
      }

      &__session_details {
        display: flex;
        align-items: center;
        gap: 5px;

        & > p {
          @include body2;
        }

        & > span {
          @include body3;
          color: var(--btn-violet-primary);
        }
      }
    }

    &__therapist_block {
      display: flex;
      flex-direction: column;
      gap: 24px;
      height: 80%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__btn {
      @include body6;
      width: fit-content;
      padding: 8px;
      background-color: var(--btn-violet-primary);
      color: var(--base-100);
      border-radius: 8px;
      cursor: pointer;
    }

    &__btn_gray {
      @include body6;
      width: fit-content;
      padding: 8px;
      background-color: var(--grey-secondary);
      color: var(--grey-primary);
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 22;
    background-color: rgba(0, 0, 0, 0.111);
    top: 0;
    left: 0;
    border-radius: 16px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .save {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      border-radius: 8px;
      width: max-content;

      & > svg {
        width: 20px;
        height: 20px;
      }

      &__error {
        & > path {
          fill: #d75221;
        }
      }

      & > p {
        @include body5;
      }
    }
  }
}

.approved_data {
  display: flex;
  align-items: center;
  gap: 5px;

  & > span {
    @include body2;
  }

  & > p {
    @include body3;
    color: var(--btn-violet-primary);
  }
}

.edit {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: var(--btn-violet-primary);
  color: var(--base-white);
  @include body5;
  padding: 8px;
  width: 100px;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 18px;
    height: 18px;

    & > path {
      stroke: var(--base-white);
    }
  }
}

.textarea_wrap {
  position: relative;
  width: 100%;
  margin-bottom: 8px;

  & > textarea {
    resize: none;
    border-color: var(--btn-violet-primary);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > img {
    top: 33px;
    right: 5px;
  }
}

.error {
  left: auto;
  right: 8px;
  bottom: -22px;
}

.versions_container {
  display: flex;
  align-items: center;
  gap: 16px;

  @include body6;
  font-size: 16px;

  margin-top: 10px;

  &__selected_tab {
    @include body4;
    font-size: 16px;
    width: 100%;
    text-align: center;
    color: var(--btn-violet-primary);
    border-bottom: 2px solid var(--btn-violet-primary);
    padding-bottom: 3px;
    border-radius: 4px;
    cursor: default;
  }

  &__tab {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--btn-violet-disabled);
    color: var(--btn-violet-disabled);
    padding-bottom: 3px;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media (max-width: 1075px) {
  .buttons {
    flex-direction: column;
    gap: 16px;
  }
}

@media (max-width: 600px) {
  .main_container {
    left: 50%;
    transform: translate(-50%, -50%);

    & h6,
    h4 {
      text-align: left;
    }

    &__content {
      &__info_block {
        & > p {
          font-size: 14px;
        }

        &__session_details {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          & > p {
            @include body5;
          }

          & > span {
            @include body6;
          }
        }
      }
    }
  }
}
