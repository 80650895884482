@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.main_container {
  padding: 24px 102px 24px 102px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tabs {
  margin-top: 12px;
  display: flex;
  gap: 16px;
  padding-bottom: 10px;
  @include body3;
  color: var(--grey-primary);

  & > p {
    cursor: pointer;
  }

  &__selected_tab {
    @include body2;
    color: var(--text-primary);
    border-bottom: 2px solid var(--btn-pink-primary);
  }
}
