.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinnerBox {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.firstOrbit {
  width: 165px;
  height: 165px;
  border: 2px solid var(--btn-violet-primary);
  animation: spin3D 4s linear .2s infinite;
}

.secondOrbit {
  width: 120px;
  height: 120px;
  border: 2px var(--btn-violet-primary);
  animation: spin3D 3s linear 0s infinite;
}

.thirdOrbit {
  width: 90px;
  height: 90px;
  border: 2px solid var(--btn-violet-disabled);
  animation: spin3D 2s linear 0s infinite;
}

.fourthOrbit {
  width: 60px;
  height: 60px;
  border: 2px solid var(--btn-violet-primary);
  animation: spin3D 1s linear 0s infinite;
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5, .5, .5, 360deg);
  }

  to {
    transform: rotate3d(0deg);
  }
}