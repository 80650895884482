@import '../../../theme/scss/mixins.scss';

.main_container {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 64px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  &__onboarding_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
  }

  &__content {
    width: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &>h4 {
        color: var(--btn-violet-primary);
      }
    }
  }
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 8px auto;
  gap: 12px;
  color: #8450A0;
  cursor: pointer;
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  color: #C7BCCE;
  cursor: pointer;

  &__back {
    display: flex;
    align-items: center;
    gap: 12px;

    color: #8450A0;
    font-weight: 500;
  }

  &__back_disabled {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: default;

    color: #C7BCCE;
    font-weight: 500;

    & svg {
      &>path {
        stroke: #C7BCCE;
      }
    }
  }

  &__next {
    padding: 10px 20px;
    width: max-content;
    background: var(--type-purpure100-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;

    color: #FFFFFF;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 23px;
  }

  &__next_disable {
    padding: 10px 20px;
    width: max-content;
    background: #C7BCCE;
    border: none;
    border-radius: 50px;

    color: #FFFFFF;
    font-family: var(--poppins-medium-500);
  }
}

.input_text_area {
  width: 525px;
  border: none;
}

.input_container {
  align-items: flex-end;
  width: 100%;
  margin-top: -10px;
  
  &>textarea {
    width: 100%;
  }
}

.btn_decline_therapist {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btn_decline_therapist;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none
  }

  &__cancel {
    @extend .btn_decline_therapist;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}