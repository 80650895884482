.slider {
    width: 50%;
    height: calc(100vh - 73px);

    & > div button[type="button"] {
        display: none !important;
    }

    & ul {
        position: absolute;
        bottom: 50px;
        left: 100px;
        display: flex !important;
        justify-content: flex-start;
        width: 485px;

        & > li {
            width: 17px;
            height: 17px;
            margin: 0 9px;

            & > button {
                display: block;
                width: 17px;
                height: 17px;
                border: 1px solid var(--white-color);
                border-radius: 50%;
                background: transparent;
            }

            & > button::before {
                display: none;
            }
        }

        & > li[class="slick-active"] {
            & > button {
                background-color: var(--white-color);
            }
        }
    }
}

.slider_card {
    display: block !important;
    width: 100% !important;
    height: calc(100vh - 73px) !important;
    object-fit: cover;
    cursor: pointer;
}

.slide1 {
    background: url('../../../theme/assets/images/parent_slide1.jpeg') no-repeat;
    background-size: cover;
}

.slide2 {
    background: url('../../../theme/assets/images/parent_slide2.jpeg') no-repeat;
    background-size: cover;
}

.slide3 {
    background: url('../../../theme/assets/images/parent_slide3.jpeg') no-repeat;
    background-size: cover;
}

.slide4 {
    background: url('../../../theme/assets/images/therapist_slide1.jpeg') no-repeat;
    background-size: cover;
}

.slide5 {
    background: url('../../../theme/assets/images/therapist_slide2.jpeg') no-repeat;
    background-size: cover;
}

.slide6 {
    background: url('../../../theme/assets/images/therapist_slide1.jpeg') no-repeat;
    background-size: cover;
}

.card_descr {
    position: relative;
    top: calc(100% - 200px);
    left: 110px;
    display: flex;
    flex-direction: column;
    width: 485px;

    & > blockquote {
        margin-bottom: 20px;
        font-family: Poppins_SemiBold, sans-serif;
        font-size: var(--title-fsize);
        line-height: 24px;
        color: var(--white-color);
    }

    & > figcaption {
        font-family: Poppins_Bold, sans-serif;
        font-size: var(--title-fsize);
        line-height: 24px;
        color: var(--white-color)
    }
}
