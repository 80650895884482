@import '../../../theme/scss/mixins.scss';

.main {
  width: 100%;
  height: 100%;
  padding: 40px 102px 0 102px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  gap: 24px;
  min-height: 90%;
  max-height: 100%;
  overflow: hidden;
}

.profile_info {
  width: 69%;
  border-radius: 12px;
  background-color: var(--base-white);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;


  &__child {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__info_block {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--btn-violet-secondary);
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__two {
      display: flex;
    }

    &__item {
      width: 50%;
      display: flex;
      flex-wrap: wrap;

      &>span {
        @include body6;
        width: 50%;
        color: var(--grey-primary);
      }

      &>p {
        @include body5;
        white-space: nowrap;
        overflow: hidden;
        max-width: 40%;
        text-overflow: ellipsis;
      }
    }
  }

  &__therapists_block {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--btn-violet-secondary);
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 40%;
    overflow: scroll;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &>span {
        @include body6;
        color: var(--grey-primary);
      }
    }

    &__assigned {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;

      &>p {
        @include body6;
      }
    }

    &__matched {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &>p {
        @include body6;
      }
    }
  }

  &__advisor_block {
    display: flex;
    gap: 16px;

    &__info {
      display: flex;
      flex-direction: column;

      &>p {
        @include body5;
      }

      &>span {
        @include body5;
        color: var(--grey-primary);
      }
    }
  }
}

.records_info {
  width: 29.5%;
  border-radius: 12px;
  background-color: var(--base-white);
  padding: 4px;

  max-height: 100%;
  overflow: scroll;

  &__scroll {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px;
    max-height: 100%;
    overflow: scroll;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-family: var(--poppins-poppins-semiBold);
      font-size: 16px;
      line-height: 22px;
    }

    span {
      font-family: var(--poppins-regular);
      font-size: 16px;
      line-height: 22px;
      color: #626262;
    }
  }
}

.user_container {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: var(--btn-violet-secondary);
  color: var(--btn-violet-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    width: inherit;
    height: inherit;
    border-radius: 6px;
    object-fit: cover;
  }
}

.therapist_container {
  display: flex;
  justify-content: space-between;

  &__content {
    display: flex;
    gap: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;

    &>p {
      @include body5;
    }

    &>span {
      @include body6;
      color: var(--grey-primary)
    }

    &__orig_price {
      font: 400 14px/20px var(--poppins-regular) !important;
    }

    &__changed_price {
      display: flex;
      gap: 12px;

      &>p {
        @include body6;
      }

      &>span {
        @include body6;
        color: var(--grey-primary);
        text-decoration: line-through;
      }
    }
  }

  &__btn {
    width: 120px;
    height: 45px;
    @include body5;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--btn-pink-primary);
    border-radius: 50px;
    color: var(--btn-pink-primary);
    cursor: pointer;
    margin-right: 24px;
  }
}

.violet_btn {
  @include body2;
  color: var(--btn-violet-primary);
  cursor: pointer;
  text-decoration: underline;
}

.child_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}