@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    max-width: 50%;

    h4 {
      text-align: center;
      color: var(--btn-violet-primary);
    }

    & > p {
      @include body3;
      text-align: center;
    }
  }

  &__form {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    max-height: 80%;
    padding-right: 16px;

    &__selector {
      display: flex;
      flex-direction: column;
      gap: 12px;

      & > p {
        @include body6;
        text-align: center;
        color: var(--text-secondary);
      }
    }
  }

  &__behavior_container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    width: 80%;

    &__item {
      cursor: pointer;
      @include body4;
      display: flex;
      padding: 12px;
      padding-right: 35px;
      border-radius: 50px;
      border: 1px solid var(--btn-violet-primary);
      color: var(--btn-violet-primary);
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &__selected {
      @extend .main_container__behavior_container__item;
      background-color: var(--btn-violet-primary);
      color: var(--base-white);

      & > svg {
        width: 16px;
        min-width: 16px;
        height: 20px;
      }
    }
  }

  &__footer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__back {
      display: flex;
      align-items: center;
      gap: 10px;

      @include body2;
      color: var(--btn-violet-primary);

      cursor: pointer;

      & > svg {
        width: 20px;
        height: 20px;

        & path {
          stroke-width: 2;
          stroke: var(--btn-violet-primary);
        }
      }
    }
  }

  &__next {
    display: flex;
    align-items: center;
    gap: 10px;

    @include body2;
    color: var(--btn-violet-primary);

    cursor: pointer;

    & > svg {
      width: 20px;
      height: 20px;

      & path {
        stroke-width: 2;
      }
    }

    &__disabled {
      @extend .main_container__next;
      color: var(--btn-violet-disabled);
      cursor: default;

      & > svg > path {
        stroke: var(--btn-violet-disabled);
      }
    }
  }
}

.text_field_ar {
  direction: rtl;

  & > div > div > div {
    & > svg {
      margin: 0 !important;
    }
  }
}

.date_picker_ar {
  & > div > input {
    direction: rtl;
    padding-right: 50px !important;
  }
}

.text_field_en {
  direction: ltr;
}
.date_picker_en {
  direction: ltr;
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .main_container {
    &__header {
      max-width: 70%;
    }

    &__form {
      width: 70%;
    }

    &__footer {
      width: 70%;
    }

    &__behavior_container {
      max-height: 60%;
      overflow-y: scroll;
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    &__header {
      max-width: 90%;
    }

    &__form {
      width: 90%;
    }

    &__behavior_container {
      max-height: 60%;
      overflow-y: scroll;

      &__item {
        text-align: center;
      }
    }

    &__footer {
      width: 90%;
    }
  }
}

@media (max-width: 450px) {
  .main_container__behavior_container__item {
    width: 100%;
    min-height: 60px;
    text-align: left;
  }
}
