.main_container {
  background: #ffffff;
  width: 809px;
  height: 95vh;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.scroll {
  overflow-y: scroll;
}

// .scroll::-webkit-scrollbar {
//   display: none;
// }

.header {
  position: relative;
  left: 95%;
  width: fit-content;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee9f3;

  .photo_container {
    display: flex;
    gap: 16px;
    align-items: center;

    &__photo {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee9f3;
      border-radius: 12px;
      color: #8450a0;
      font-family: var(--poppins-medium-500);
      font-size: 16px;
      line-height: 22px;
    }

    p {
      font-family: var(--poppins-poppins-semiBold);
      line-height: 33px;
      font-size: 24px;
    }
  }

  .main_info {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__item {
      display: flex;
      align-items: center;
      gap: 40px;

      span {
        width: 20%;
        font-family: var(--poppins-regular);
        line-height: 20px;
        font-size: 14px;
        color: #626262;
      }

      p {
        width: max-content;
        font-family: var(--poppins-medium-500);
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
}

.country_container {
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    width: max-content !important;
    font-family: var(--poppins-medium-500) !important;
    line-height: 20px !important;
    font-size: 14px !important;
    color: #171717 !important;
  }
}

.advisor_section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee9f3;

  .advisor_container {
    display: flex;
    gap: 24px;
    align-items: center;
  }

  .photo {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee9f3;
    color: #8450a0;
    font-family: var(--poppins-medium-500);
    font-size: 16px;
    line-height: 22px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;

    p {
      font-family: var(--poppins-medium-500);
      line-height: 20px;
      font-size: 14px;
    }

    span {
      font-family: var(--poppins-regular);
      line-height: 20px;
      font-size: 14px;
      color: #626262;
    }
  }
}

.question_section {
  display: flex;
  flex-direction: column;

  h2 {
    font-family: var(--poppins-poppins-semiBold);
    line-height: 28px;
    font-size: 20px;
  }

  .answers {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 31px;
    padding-right: 15px;
  }

  .text_container {
    display: flex;
    align-items: flex-start;
    gap: 16px;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: var(--poppins-medium-500);
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .number {
    margin-top: 3px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: #c7bcce;
  }

  .answer_text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-family: var(--poppins-medium-500);
    color: #626262;
    word-break: keep-all;
  }
}
