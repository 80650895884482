@import '../../../../theme/scss/mixins.scss';

.contactDetails {
  padding: 24px;
  border-left: 1px solid var(--border-color);
  box-shadow: 0px 1px 30px 0px rgba(157, 157, 209, 0.16);
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--base-white);
  width: 350px;

  &__details {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__avatar {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__placeholder {
        width: 80px;
        height: 80px;
        background-color: var(--purple200-background);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;

        & > img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          border-radius: 12px;
        }

        svg {
          width: 60px;
          height: 60px;

          path {
            stroke: var(--type-purpure100-color);
          }
        }
      }

      &__closeBtn {
        cursor: pointer;

        &:hover {
          svg path {
            stroke: var(--type-purpure200-color);
          }
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &__name {
      @include body2;
      margin-top: 8px;
    }

    &__position {
      @include body6;
      margin-top: 4px;
      color: var(--grey-primary);
    }

    &__status {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        color: var(--text-gray);
        font-size: 14px;
        font-family: Poppins_Regular, sans-serif;
      }

      &__icon {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__online,
        &__offline {
          width: 8px;
          height: 8px;
          background-color: var(--state-success);
          border: 1px solid var(--state-success);
          border-radius: 999px;
        }

        &__offline {
          background: white;
          border: 1px solid var(--text-gray);
        }
      }
    }

    &__localTime {
      margin-top: 8px;
      display: flex;
      align-items: center;
      gap: 12px;

      p {
        color: var(--text-gray);
        font-size: 14px;
        line-height: 140%;
        font-family: Poppins_Regular, sans-serif;
      }
    }
  }

  &__notification {
    border-top: 1px solid var(--border-color-gray100);
    border-bottom: 1px solid var(--border-color-gray100);
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 140%;
      color: var(--text-primary);
      font-family: Poppins_Medium_500, sans-serif;
    }
  }

  &__deleteBtn {
    p {
      font-size: 16px;
      line-height: 140%;
      color: var(--type-purpure200-color);
      font-family: Poppins_Medium_500, sans-serif;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__profileBtn {
    width: 100%;
    border-radius: 50px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background-color: var(--btn-violet-primary);
    cursor: pointer;

    p {
      @include body2;
      color: var(--base-white);
    }
  }

  &__attachments {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    &__element_preview {
      border-radius: 8px;
      width: 60px;
      height: 60px;
      cursor: pointer;

      &__default {
        border-radius: 8px;
        width: 60px;
        height: 60px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--btn-violet-secondary);

        & > svg {
          stroke: var(--type-purpure200-color);

          & > path {
            stroke: var(--type-purpure200-color);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .contactDetails {
    position: absolute;
    padding-top: 96px;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 600px) {
  .contactDetails {
    position: absolute;
    padding-top: 96px;
    width: 100%;
    height: 100%;
  }
}
