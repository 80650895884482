@import '../../../theme/scss/mixins';

.signIn_form {
    position: absolute;
    top: 24%;
    left: 50%;
    transform: translate(-50%, 0);
}

.form_container {
    width: 440px;
    margin: 0 auto;
    padding: 43px 0;
}

.signIn_caption {
    @include caption;
    margin-bottom: 32px;
}

.signIn_error {
    @include errorMessage;
}

.form_item {
    @include formItem;
}

.message_wrap {
    display: flex;
    justify-content: flex-end;
}

.message {
    border: none;
    outline: none;
    font-family: Popping_Regular, sans-serif;
    font-size: var(--main-fsize);
    background: none;
    color: var(--main-text-color);
    cursor: pointer;
}

.login_btn {
    width: 100%;
    margin: 16px 0 0 0;
}

.loader {
    margin: 16px auto 0;
}
