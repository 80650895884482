.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: #FAFAFC;
}

.main {
    width: 100%;
    height: calc(100% - var(--desktop-height));
    overflow: auto;
}