@import '../../../../../theme/scss/mixins.scss';
@import '../../../../../theme/scss/variables.scss';

.channel {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  cursor: pointer;

  &:hover {
    background-color: #8450a02f;
  }

  &__participant_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__info {
      display: flex;
      align-items: center;
      gap: 5px;

      &__txt {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        &>p {
          @include body2;
        }

        &>span {
          @include body6;
          color: var(--text-secondary);
          max-width: 120px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

    }

    &__collapse {
      display: flex;
      align-items: center;
      gap: 5px;

      &__circle {
        @include subtext2;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: var(--btn-violet-primary);
        color: var(--base-white);
      }
    }
  }

  &__profiles {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
    background-color: #8450a017;
    cursor: pointer;


    &:hover {
      background-color: #8450a02f;
    }
  }
}

.selected_channel {
  background-color: #8450a02c;
}

.selected_profile {
  background-color: #8450a03a;
  color: var(--btn-violet-primary);
}

.new_profile {
  color: #8450a087
}
