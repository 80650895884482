@import '../../../theme/scss/mixins';

.main_container {
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.header {
    display: flex;
    align-items: center;
    gap: 8px;

    &>svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.info_caption {
    @include popupCaption;
}

.close_btn {
    cursor: pointer;
}

.avatar_input {
    position: absolute;
    height: .4px;
    width: .4px;
}

.avatar_wrapper {
    position: relative;
    font-size: var(--main-fsize);
    line-height: 24px;
}

.avatar_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    color: var(--black500-color);
}

.avatar_message {
    padding: 0 12px 10px;
    text-align: center;
    color: var(--secondary-text-color);
}

.error {
    font-size: var(--small-fsize);
    line-height: 14px;
    white-space: nowrap;
    color: var(--error-color);
}

.btn_container {
    display: flex;
    justify-content: flex-end;
}

.save_btn {
    @include body2;
    margin-bottom: 0;
    margin-top: 0;
    width: 150px;
}

.delete_btn {
    @include body2;
    border: 1px solid var(--btn-pink-primary);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    color: var(--btn-pink-primary);
    cursor: pointer;
}

.btns {
    display: flex;
    justify-content: center;
    gap: 16px;
}

.loader {
    margin: 24px auto 0;
}