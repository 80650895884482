@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  &>h4 {
    color: var(--btn-violet-primary)
  }

  &__text {
    margin-top: -20px;
    @include body3;
    font-size: 18px;
    text-align: center;
    width: 40%;
    
    &>span {
      @include body1;
      font-size: 18px;
      color: var(--btn-violet-primary)
    }
  }
  
  &__btn {
    @include body3;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--btn-violet-primary);
    color: var(--base-white);
    border-radius: 50px;
    width: 20%;
    height: 40px;
    cursor: pointer;
  }
}

@media (max-width: 900px) {
  .main_container__text {
    width: 70%;
  }

  .main_container__btn {
    width: 40%;
  }
}

@media (max-width: 700px) {
  .main_container__text {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .main_container{
    &>svg {
      width: 90%;
      height: 200px;
    }

    &__btn {
      width: 60%;
    }
  }
}