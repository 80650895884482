.fileAttachmentLoader {
  display: flex;
  justify-content: flex-end;


  &__content {
    background: #ffffff;
    height: 66px;
    width: 320px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 12px;
  }

  &__text {
    width: 80%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: var(--type-purpure100-color);
    }

    p {
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--text-secondary);
    }
  }
}