@import '../../../theme/scss/mixins.scss';

.main_container {
  background: #ffffff;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  padding: 64px 24px 24px 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 40px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}

.icon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.btns_vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.btns_horizontal {
  width: 100%;
  display: flex;
  gap: 26px;
}

.input_text_area {
  border: 1px solid #c7bcce;
}

.loading_conatiner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .main_container {
    outline: none;
  }
}

@media (max-width: 600px) {
  .modal {
    min-width: 320px;
    margin-inline: 16px;
  }
  .main_container {
    outline: none;
    width: 100% !important;
  }
}
