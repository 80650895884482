@import '../../../theme/scss/mixins';

.main_container {
  padding: 40px 102px 0 102px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_container {
  width: 100%;
  display: flex;
  background: var(--base-white);
  border-radius: 12px;

  &__info {
    width: 50%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--btn-violet-secondary);
    gap: 36px;

    &__new_profile {
      min-height: 600px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 40px 20px;

      &__selector {
        display: flex;
        align-items: center;
        gap: 15px;
        width: max-content;
        margin-bottom: 30px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
      }

      & > svg {
        width: 350px;
        height: 350px;
      }

      &__content {
        width: 80%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;

        & > h3 {
          font-size: 30px;
          text-align: center !important;
        }

        & > p {
          @include body3;
          text-align: center;
          color: var(--grey-primary);
        }

        & > div {
          @include body3;
          color: var(--base-white);
          background-color: var(--btn-violet-primary);
          width: 40%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          height: 45px;
          cursor: pointer;
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .menu_icon {
      cursor: pointer;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__child {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &__item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          @include body2;
        }

        span {
          @include body3;
        }
      }
    }

    &__personal {
      display: flex;
      align-items: center;
      gap: 24px;

      &__photo {
        width: 80px;
        height: 80px;
        border-radius: 12px;
        background: var(--btn-violet-secondary);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__therapists_main {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 25px;
      border-top: 1px solid #eee9f3;

      & > p {
        @include body2;
      }

      &__advisor {
        width: 100%;
        background: #fafafc;
        border-radius: 12px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        border: 1px solid #f6f7fa;

        &__content {
          display: flex;
          gap: 16px;
          align-items: center;

          &__info {
            & > p {
              @include body5;
            }

            & > span {
              @include body6;
              color: var(--grey-primary);
            }
          }
        }

        .advisor_type {
          font-family: var(--poppins-regular);
          font-size: 14px;
          line-height: 20px;
          color: #626262;
        }

        &__photo {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eee9f3;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            border-radius: 6px;
          }
        }
      }

      &__container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        overflow: scroll;
      }

      &__therapists {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-top: 16px;

        &__photo {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f6f0fc;

          .icon {
            width: 18px;
            height: 18px;
          }
        }

        span {
          font-family: var(--poppins-regular);
          font-size: 16px;
          color: #9b9b9b;
          line-height: 23px;
        }
      }
    }
    .title_block {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    .title_icon {
      position: relative;
      width: 24px;
      height: 24px;
    }
    .title_handle {
      position: absolute;
      width: 24px;
      height: 24px;
      z-index: 40;
    }

    .profile_select {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      right: 0;
      width: fit-content;
      max-width: 180px;
      background-color: #ffffff;
      border-radius: 12px;
      z-index: 40;
      max-height: 150px;
      overflow: scroll;
      box-shadow: 1px 1px 1px var(--black300-color), -1px -1px 1px var(--black300-color), -1px 1px 1px var(--black300-color), 1px -1px 1px var(--black300-color);

      &::-webkit-scrollbar {
        display: none;
      }

      &_item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 12px 15px;
        gap: 5px;
        border-bottom: 1px solid var(--black200-color);

        & > p {
          font-family: var(--poppins-regular);
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        & > span {
          font-family: var(--poppins-medium-500);
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #8450a08c;
        }

        &:hover {
          color: var(--btn-violet-primary);
        }
      }

      &_icon {
        margin-right: 10px;
        width: 25px;
        height: 25px;
        padding: 5px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee9f3;
      }
    }
  }

  &__history {
    width: 50%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    span {
      font-family: var(--poppins-regular);
      font-size: 16px;
      line-height: 23px;
      color: #9b9b9b;
    }

    &__header {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &__visits {
        display: flex;
        gap: 16px;
        align-items: center;

        h2 {
          font-family: var(--poppins-poppins-semiBold);
          font-size: 16px;
          line-height: 23px;
        }

        span {
          font-family: var(--poppins-regular);
          font-size: 16px;
          line-height: 23px;
        }
      }

      span {
        font-family: var(--poppins-medium-500);
        font-size: 16px;
        line-height: 23px;

        color: #c7bcce;
        cursor: pointer;
      }
    }
  }
}

.records {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 10px;
  max-height: auto;
  margin-bottom: 10px;
  // overflow-y: scroll;
}

.icon {
  width: 24px;
  height: 24px;
}

.spiner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.popup_text {
  @include body6;
}

.btn_decline_application {
  @include body2;
  width: 100%;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;

  &__delete {
    @extend .btn_decline_application;
    color: var(--base-white);
    background-color: var(--btn-violet-primary);
    border: none;
  }

  &__cancel {
    @extend .btn_decline_application;
    color: var(--btn-pink-primary);
    background-color: var(--base-white);
    border: 1px solid var(--btn-pink-primary);
  }
}

.violet_btn {
  @include body2;
  color: var(--btn-violet-primary);
  cursor: pointer;
  text-decoration: underline;
}

.violet_btn_contained {
  @include body2;
  color: var(--base-white);
  cursor: pointer;
  background-color: var(--btn-violet-primary);
  padding: 10px 20px;
  border-radius: 50px;
}

.therapist_btn {
  @include body5;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.child_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accepted_therapists_container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body2;
  }

  &__therapists {
    display: flex;
    gap: 24px;
    overflow: scroll;
  }
}

@media screen and (min-width: 601px) and (max-width: 1150px) {
  .main_container {
    padding: 40px;
  }
  .content_container {
    flex-direction: column;

    &__info {
      width: 100%;
    }

    &__history {
      width: 100%;
    }
  }
}

@media (max-width: 900px) {
  .content_container__info__new_profile__content {
    & > div {
      width: 70%;
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    padding: 16px;
  }
  .content_container {
    flex-direction: column;

    &__info {
      width: 100%;
      padding: 16px;
      margin-bottom: 24px;

      .child_header {
        flex-direction: column;
        align-items: flex-start;
      }

      &__personal {
        margin-bottom: 10px;
      }

      &__new_profile__content {
        & > h3 {
          font-size: 25px;
          text-align: center !important;
        }

        & > div {
          width: 140%;
        }
      }
    }

    &__history {
      width: 100%;
      padding-inline: 16px;
    }
  }
}
