@import '../../../theme/scss/mixins';

.form_container {
  width: 440px;
  margin: 0 auto;
  padding: 64px 0 43px 0;
}

.signIn_caption {
  margin-bottom: 12px;
}

.signIn_error {
  @include errorMessage;
  position: absolute;
  top: -9%;
}

.form_item {
  @include formItem;
}

.password_item {
  @extend .form_item;
  margin-bottom: 24px;
}

.message_wrap {
  @include body5;
  color: var(--btn-violet-primary);
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.message {
  @include body5;
  border: none;
  outline: none;
  background: none;
  color: var(--btn-violet-primary);
  cursor: pointer;
}

.clarification {
  @include body6;
  text-align: center;
  border-top: 1px solid var(--btn-violet-secondary);
  padding-top: 24px;

  & span:first-child {
    color: var(--text-primary);
    display: inline-block;
    margin-right: 12px;
  }
}

.account_btn {
  @include link1;
  display: inline-block;
  color: var(--btn-violet-primary);
}

.login_btn {
  @include body2;
  width: 100%;
  margin: 10px 0 20px 0;
  background-color: var(--btn-violet-primary);
}

.captcha {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.link_text {
  @include body6;
  color: var(--text-primary);
}

.link {
  @include body5;
  display: inline-block;
  text-align: center;
  border: none;
  outline: none;
  background: none;
  color: var(--btn-violet-primary);
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 600px) {
  .signIn_form {
    width: 100%;
  }
  .form_container {
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
  }

  .signIn_caption {
    text-align: center;
  }

  .signIn_link_block {
    text-align: center;
  }

  .link {
    margin-top: 8px;
  }
}
