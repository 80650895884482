.avatar {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  &__placeholder {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: var(--purple200-background);

    svg path {
      stroke: var(--type-purpure100-color);
    }

    &>img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }

  &__online,
  &__offline {
    width: 8px;
    height: 8px;
    background: var(--state-success);
    border: 1px solid #ffffff;
    border-radius: 999px;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__offline {
    background: var(--black500-color);
  }
}