@import '../../../../../theme/scss/mixins.scss';

.newMessageBar {
  width: 100%;
  max-height: 400px;
  padding: 0 24px;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 16px;
  overflow: hidden;

  &__innerWrap {
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;

    border: 1px solid var(--border-color);
    border-radius: 12px;
    background-color: white;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  &__textField {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    gap: 18px;

    &__error {
      border-color: var(--error-color);
    }

    &__inputWrap {
      width: 100%;
      position: relative;

      input {
        width: 100%;
        padding: 16px;
        border: none;
        padding-right: 16px;
      }
    }

    &__uploader {
      cursor: pointer;
      height: 24px;
      width: 24px;

      svg {
        height: 24px;
        width: 24px;
      }

      &:hover {
        svg path {
          stroke: var(--type-purpure200-color);
        }
      }
    }
  }
}

.preview {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  padding: 16px;
  border-top: 1px solid #eaeaea;
  flex-grow: 1;
  overflow-y: auto;

  &__item {
    width: 320px;
    padding: 8px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__preview {
      width: 80%;
      display: flex;
      align-items: center;
      gap: 8px;

      &__imgWrap {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 12px;
        background-color: var(--purple200-background);

        & > img {
          width: 40px;
          height: 40px;
        }

        img,
        video,
        svg {
          display: block;
          height: 48px;
        }

        svg {
          path {
            stroke: var(--type-purpure100-color) !important;
          }
        }
      }

      &__description {
        width: 80%;
        &__name {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          @include body6;
          color: var(--btn-violet-primary) !important;
        }

        &__size {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: var(--text-secondary);
        }
      }
    }

    &__deleteIconWrap {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 6px;
      border: 1px solid #eaeaea;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        border-color: var(--type-purpure200-color);

        svg path {
          stroke: var(--type-purpure200-color);
        }
      }
    }
  }
}
