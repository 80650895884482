@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.tabs {
  display: flex;
  gap: 40px;
}

.tab {
  display: flex;

  & span {
    @include body2;
    color: var(--grey-primary);
  }
}
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.chat {
  position: relative;

  &>svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: -30px;

    animation: bounce 1s infinite;

    &>path {
      fill: var(--btn-pink-primary);
    }
  }
}

.active {
  @extend .tab;

  & span {
    box-sizing: border-box;
    color: var(--text-primary);
    padding-bottom: 10px;
    border-bottom: 2px solid var(--btn-pink-primary);
  }
}

@media (max-width: 600px) {
  .tabs {
    flex-direction: column;
    gap: 20px;
  }
  .tab {
    align-self: center;
  } 
}
