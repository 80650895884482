@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: var(--base-white);
  border-radius: 16px;

  &__questionnaire {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p {
        @include body1;
        font-size: 18px;
        color: #bca0cd;
      }

      & > span {
        @include body6;
        font-size: 18px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .advisor {
          @include body3;

          & > span {
            @include body2;
          }
        }

        & > p {
          @include body2;
          color: var(--btn-violet-primary-focused);
        }

        & > span {
          @include body6;
        }
      }

      &__sub {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include body6;
        margin: 12px 0;

        & > div {
          & > span {
            @include body2;
            color: var(--btn-violet-primary-focused);
          }
        }
      }
    }

    .optional_sections {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > h6 {
        font-size: 18px;
        color: #bca0cd;
      }

      & > p {
        @include body2;
        color: var(--btn-violet-primary-focused);
      }

      &__item {
        & > span {
          @include body6;
          font-size: 16px;
        }

        & > div {
          @include body3;

          & > p {
            @include body2;
            color: var(--btn-violet-primary-focused);
          }
        }
      }
    }
  }
}
