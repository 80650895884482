@import '../../../../../theme/scss/mixins.scss';

.show_less {
  @include body5;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 100px;
  color: var(--text-gray);
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.show_all {
  @include body5;
  color: var(--text-gray);
}

.content {
  &__note_container {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;

    position: relative;
    border-radius: 12px;
    background-color: var(--base-white);

    &__content {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &>span {
        @include body5;
      }
    }


    &>p {
      bottom: 24px;
      @include body2;
    }
  }
}

.show_btn {
  display: flex;
  gap: 3px;
  align-items: center;
  cursor: pointer;

  &>p {
    @include body5;
    color: var(--btn-violet-primary);
  }

  &>svg {
    width: 25px;
    height: 25px;
  }
}