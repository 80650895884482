@import 'scss/reset';
@import 'scss/fonts';
@import 'scss/variables';
@import 'scss/mixins';

html,
body {
    font-family: Poppins_Regular, sans-serif;
    font-style: normal;
    scroll-behavior: smooth;
}

#root,
html,
body {

    // Scrollbar

    ::-webkit-scrollbar {
        width: 5px;
        height: 4px;    
    }

    ::-webkit-scrollbar-track {
        background-color: inherit;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--btn-violet-disabled);
        border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: var(--btn-violet-disabled);
    }

    // Disable error overlay
    iframe#webpack-dev-server-client-overlay {
        display: none;
    }
}