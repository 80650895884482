@import '../../theme/scss/mixins.scss';
@import '../../theme/scss/variables.scss';

.header {
  height: var(--desktop-height);
  padding: 16px 40px;
  background-color: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(157, 157, 209, 0.16);
  position: relative;
}

.language {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--base-white);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 10px;
  border-radius: 8px;
  border: 1px solid var(--text-secondary);
  gap: 15px;

  &__selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    & > div {
      display: flex;
      align-items: center;
      gap: 10px;

      & > img {
        width: 20px;
        height: 20px;
      }

      & > p {
        @include body6;
        color: var(--text-secondary);
      }
    }

    & > svg {
      width: 20px;
      height: 20px;

      & path {
        stroke: var(--text-secondary);
      }
    }
  }
}
.language_selector {
  position: absolute;
  padding: 16px;
  bottom: -20px;
  left: 0;
}

.mobile_content {
  display: none;
}

.menu_bar {
  display: none;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.logo_wrap {
  display: inline-block;

  & .logo {
    display: inline-block;
  }
}

.login_wrap {
  display: flex;
  align-items: center;
  position: relative;

  .faq {
    width: 17px;
    height: 16px;
    background-image: url('../../theme/assets/icons/question.svg');
    background-repeat: no-repeat;
  }

  &__help {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border: 1.5px solid var(--btn-violet-primary);
    border-radius: 8px;
    padding: 5px 10px;
    cursor: pointer;

    & > p {
      @include body2;
      color: var(--btn-violet-primary);
    }
  }
}

.tooltip {
  background-color: #ffffff !important;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
  padding: 4px 32px !important;
}

.tooltip_arrow {
  color: #ffffff !important;
}

.tooltip_text {
  display: flex;
  align-items: center;
  color: #707070;
  font-size: var(--main-fsize);
  line-height: 24px;
}

.file_icon {
  width: 16px;
  height: 16px;
  background-image: url('../../theme/assets/icons/download_file.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 6px;
}

.login {
  display: flex;
  align-items: center;

  .login_wrap {
    display: flex;
    align-items: center;
    gap: 16px;

    & > svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.login_burger {
  @extend .login;
  display: none;

  .globe_icon {
    fill: var(--btn-violet-primary);
  }
}

.envelop {
  width: 20px;
  height: 20px;

  animation: bounce 1s infinite;

  & > path {
    fill: var(--btn-pink-primary);
  }
}

.lang_btn {
  font-family: Poppins_Regular, sans-serif;
  font-size: 16px;
  background: none;
  color: #333333;
  border: none;
  outline: none;
}

.login_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 40px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  outline: none;
  font-family: Poppins_Regular, sans-serif;
  font-size: 16px;
  color: #ffffff;
  background: var(--primary800-color);
  cursor: pointer;
}

.icon {
  margin-right: 30px;
}

.login_mobile {
  display: none;
}

.support {
  @include body5;
  color: var(--btn-violet-primary);
  font-weight: 600 !important;
  border: 1px solid var(--btn-violet-primary);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px;

  & > svg {
    width: 18px;
    height: 18px;
    fill: var(--btn-violet-primary);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 1100px) {
  .header {
    padding-inline: 10px;
  }
  .content {
    display: none;
  }
  .login {
    display: none;
  }

  .login_mobile {
    display: block;
    z-index: 50;
  }
  .menu_bar {
    width: 24px;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;

    .menu_line {
      width: 100%;
      height: 2px;
      background-color: #171717;
    }
  }
  .login_burger {
    display: flex;
    align-items: center;
    gap: 16px;

    & > svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .mobile_content {
    position: absolute;
    left: 0;
    top: 70px;
    display: flex;
    height: 100vh;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #09010d61;
    z-index: 40;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      padding-block: 16px;
      background-color: #f4f6f7;
    }
  }

  .support {
    border: none;
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .logo_wrap {
    width: 40%;

    & > img {
      width: 100%;
    }
  }
}
