#zmmtg-root {
  display: none;
  min-width: 0 !important;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

p {
  margin-bottom: 0px !important;
}

a:hover,
a:focus {
  color: #111111 !important;
  text-decoration: none !important ;
}

a:focus {
  outline: none !important;
  outline-offset: none;
}
