@import '../../../theme/scss/mixins';

.main_container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;

  &>svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.close_btn {
  cursor: pointer;
}

.inner_container {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 70px;
}

.text_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.paragraph {
  color: #171717;
  font: 500 14px/20px var(--poppins-medium-500);
}

.description {
  color: #9B9B9B;
  font: 400 12px/17px var(--poppins-regular);
}

.btn_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  &__next {
    padding: 10px 20px;
    background: #8450A0;
    border: none;
    border-radius: 50px;
    color: #FFFFFF;
    font: 500 16px/22px var(--poppins-medium-500);
    cursor: pointer;
  }

  &__next_disabled {
    padding: 10px 20px;
    background: var(--btn-violet-disabled);
    border: none;
    border-radius: 50px;
    color: #FFFFFF;
    font: 500 16px/22px var(--poppins-medium-500);
  }
}

.files {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__file {
    display: flex;
    gap: 4px;
    width: 100%;
    background: #F6FAFE;
    border: 1px solid #EEEEEE;
    border-radius: 50px;
    padding: 2px 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .text {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      &>svg {
        width: 16px;
        height: 16px;
      }

      &>p {
        @include body6;
        color: var(--btn-violet-primary);
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &>span {
        @include subtext3;
        color: var(--text-secondary);
      }
    }

    &__delete {
      width: 32px;
      height: 32px;
      border-radius: 999px;
      border: 1px solid #EEE;
      background-color: var(--base-white);
      padding: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      bottom: 15px;
      cursor: pointer;
    }
  }

  &__file_name {
    color: #8450A0;
    font: 400 14px/20px var(--poppins-regular);
  }

  .close_icon {
    height: fit-content;
    cursor: pointer;
  }

}

.spinner {
  position: absolute !important;
  top: 50%;
  left: 45%;
}