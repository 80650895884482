@import '../../../theme/scss/mixins';

.main_block {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>p {
            @include body2;
        }

        &>svg {
            cursor: pointer;
        }
    }
}

.info_container {
    width: 100%;
    border-radius: 12px;
    background-color: var(--base-100);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__block {
        width: 100%;
        display: flex;
        gap: 24px;

        &__item {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 8px;

            &>span {
                @include body6;
                color: var(--grey-primary);
            }

            &>p {
                @include body2;
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &>span {
            @include body6;
            color: var(--grey-primary);
        }

        &>p {
            @include body2;
        }
    }

    &__documents_block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__container {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }

        &>p {
            @include body6;
        }

        &__item {
            width: 49%;
            border-radius: 999px;
            border: 1px solid var(--btn-violet-secondary);
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 3px;

            &>svg {
                width: 16px;
                height: 16px;
            }

            &>p {
                max-width: 60%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include body6;
                color: var(--btn-violet-primary);
            }

            &>span {
                @include subtext3;
                color: var(--text-secondary)
            }
        }
    }
}