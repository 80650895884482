.placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple100-background);
  flex-grow: 1;
  height: 100%;

  p {
    padding: 12px 24px;
    background: #faf1f0;
    border-radius: 40px;

    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #937673;
  }
}

@media screen and (max-width: 1023px) {
  .placeholder {
    display: none
  }
}

