@import '../../../../theme/scss/mixins.scss';

.main_container {
  position: absolute;
  background: #ffffff;
  width: 860px;
  max-height: 95%;
  padding: 24px;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container_scroll {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container_scroll::-webkit-scrollbar {
  display: none;
}

.header {
  position: relative;
  left: 95%;
  width: fit-content;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.main_info {
  display: flex;
  gap: 20px;
  width: 100%;

  &__photo {
    width: 200px;
    height: 200px;
    border-radius: 12px;
    background: #eee9f3;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: fill;

    color: #8450a0;
    font-family: var(--poppins-medium-500);
    font-weight: 500;
    font-size: 24px;

    & > img {
      width: 200px;
      height: 200px;
      border-radius: 12px;
      object-fit: fill;
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    max-width: 590px;
    gap: 6px;

    &__about_me {
      @include body6;
      color: var(--text-primary) !important;
      max-width: 100%;
    }

    p {
      @include body3;
      color: var(--grey-primary);
    }
  }
}

.personal_info {
  width: 100%;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee9f3;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__content {
    width: 100%;
    display: flex;
    gap: 24px;

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        @include body6;
        color: var(--grey-primary);
        // width: 350px;
        line-height: 20px;
        word-break: keep-all;
      }

      p {
        @include body5;
        color: var(--text-primary);
        line-height: 20px;
        word-break: keep-all;
      }
    }
  }
}

.professional_info {
  width: 100%;
  border-bottom: 1px solid #eee9f3;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;

      span {
        @include body6;
        color: var(--grey-primary);
        width: 350px;
        line-height: 20px;
        word-break: keep-all;
      }

      p {
        width: 350px;
        @include body5;
        color: var(--text-primary);
        line-height: 20px;
        word-break: keep-all;
        text-align: start;
      }
    }
  }
}

.documents_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 15px;

    &__documents {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .document_container {
      width: 30%;
      border: 1px solid #eeeeee;
      border-radius: 12px;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      .preview {
        min-width: 40px;
        height: 40px;
        border-radius: 12px;
        background: #eee9f3;
        display: flex;
        align-items: center;
        justify-content: center;

        & :last-child {
          width: 20px;
          height: 20px;
        }
      }

      .text {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;

        .name {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: #8450a0;
          font: 400 14px/20px var(--poppins-regular);
        }

        & span {
          @include body6;
          color: var(--text-secondary);
        }
      }
    }
  }
}

.country_container {
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: #171717;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--poppins-medium-500);
  }
}

.headline_files {
  @include body2;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .modal {
    margin-inline: 40px;
  }
  .main_container {
    width: 100%;
    height: 90vh;
  }
}

@media (max-width: 600px) {
  .modal {
    margin-inline: 16px;
  }
  .main_container {
    width: 100%;
    padding: 16px;
  }

  .professional_info {
    &__content {
      font-size: var(--main-fsize);
    }
  }

  .main_info {
    flex-direction: column;

    &__title {
      font-size: 16px;
      font-weight: 600;
      line-height: 22.4px;
    }

    &__photo {
      width: 40px;
      height: 40px;

      & > img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: fill;
      }
    }
  }
}
