@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 50px;
  padding: 40px;

  &>h5 {
    width: 60%;
    text-align: center;
    color: var(--btn-violet-primary);
  }

  &__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;

    &>h4 {
      width: 60%;
      text-align: center;
    }

    &__btns {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      align-items: center;
      justify-content: center;
      user-select: none;

      &__item {
        width: 40%;
        border-radius: 50px;
        border: 1px solid var(--btn-violet-primary);
        color: var(--btn-violet-primary);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        cursor: pointer;
      }

      &__selected {
        @extend .main_container__content__btns__item;
        color: var(--base-white);
        background-color: var(--btn-violet-primary);
      }
    }
  }
    
  &__footer {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    &>div {
      display: flex;
      align-items: center;
      gap: 12px;
      @include body3;
      cursor: pointer;

      color: var(--btn-violet-primary);

      &>svg {
        width: 20px;
        height: 20px;

        &>path {
          stroke-width: 2;
          stroke: var(--btn-violet-primary);
        }
      }
    }
  }
}

@media (pointer: fine) {
  .main_container__content__btns__item:hover {
    background-color: #9064aa84;
    border-color: #9064aa84;
    color: var(--base-white);
    transition: background-color 0.6s
  }
}

@media (max-width: 1200px) {
  .main_container__content {
    width: 90%;
  }
}

@media (max-width: 900px) {
  .main_container {
    &__header {
      max-width: 90%;
    }

    &__content {
      width: 90%;

      &__btns {
        width: 100%;
      }
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    padding: 30px 20px;
    overflow-y: scroll;

    &>h5 {
      width: 100%;
    }

    &__header {
      width: 100%;
      gap: 40px;

      &>div>p {
        max-width: 100%;
      }
    }

    &__content {
      width: 100%;

      &>h4 {
        width: 100%;
      }
      

      &__btns {
        flex-direction: column;

        &__item {
          width: 100%;
        }
      }
    }

    &__footer {
      width: 100%;
    }
  }
}