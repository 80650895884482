@import '../../../../theme/scss/mixins.scss';

.main_container {
  position: absolute;
  background: #FFFFFF;
  width: 860px;
  max-height: 95%;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.container_scroll {
  display: flex;
  flex-direction: column;
  max-height: 900px;
  overflow-y: scroll;
}

.container_scroll::-webkit-scrollbar {
  display: none;
}

.user_details {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 20px 0 10px 0;

  &__photo {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    background-color: var(--btn-violet-secondary);
    color: var(--btn-violet-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    display: flex;
    flex-direction: column;

    &>p {
      @include body3;
    }

    &>span {
      @include body6;
      color: var(--grey-primary);
    }
  }
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.main_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 700px;
  margin-top: 20px;
}

.input_text_area {

  &>textarea {
    @include body6;
    height: 170px !important;
    border: 1px solid var(--black300-color);
  }
}

.label {
  margin-bottom: 8px !important;
  @include body6;
}

.radio {
  width: 50%;
}

.price_field {
  display: flex;
  align-items: center;
  gap: 12px;

  &>span {
    @include body6;
    margin-top: 30px;
    height: fit-content;
    color: var(--grey-primary);
  }
}

.with_span_field {
  @extend .price_field;
  width: 50%;
}

.error_wraper {
  width: max-content;
}

.form_item {
  // width: 15%;

  &>input {
    @include body6;
  }

  &>div {
    &>input {
      @include body6;
    }
  }

}

.price_form_item {
  @extend .form_item;
  width: 15%;

}

.width {
  width: 400px;

  &>div {
    width: 100%;

    &>div {
      width: 100%;

      &>input {
        @include body6;
        width: 100%;
      }
    }
  }
}

.two_fields_container {
  width: 100%;
  display: flex;
  gap: 16px;

  &__multiselector {
    width: 50%;
  }

  &__input {
    display: flex;
    width: 50%;
    max-height: 88px;

    &__icon {
      position: relative;
      padding: 3px;
      top: 52%;
      right: 35px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      cursor: pointer;
    }

    &__icon:hover {
      background-color: var(--btn-violet-secondary);
    }
  }
}

.radio_container {
  width: 100%;
  display: flex;
  gap: 16px;
  // justify-content: space-between;
}


.btns {
  display: flex;
  gap: 16px;
  padding-top: 24px;

  &__next {
    width: 100%;
    padding: 10px 20px;
    background: #8450A0;
    border: none;
    border-radius: 50px;

    color: #FFFFFF;
    font-size: 16px;
    line-height: 23px;
    font-family: var(--poppins-medium-500);
    cursor: pointer;

    &__disabled {
      width: 100%;
      padding: 10px 20px;
      border: none;
      border-radius: 50px;

      color: #FFFFFF;
      font-size: 16px;
      line-height: 23px;
      font-family: var(--poppins-medium-500);
      background: var(--btn-violet-disabled);
    }
  }

  &__cancel {
    width: 100%;
    padding: 10px 20px;
    background: none;
    border: 1px solid #D89B95;
    border-radius: 50px;

    cursor: pointer;
    color: #D89B95;
    font-size: 16px;
    line-height: 23px;
    font-family: var(--poppins-medium-500);
  }
}