.chat {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;

  &_messagesHistory_container {
    width: 100%;
    flex-grow: 1;
  }

  .channelList_left_block {
    height: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1023px) {
  .chat {

    .channelList_left_block {
      width: 100%;
    }

    &_channelList_container {
      display: none;
    }
    &_messagesHistory_base {
      width: 100%;
      display: none;
    }

    &_messagesHistory_container {
      flex-grow: 1;
    }
  }
}

@media (max-width: 600px) {
  .chat {

    .channelList_left_block {
        width: 100%;
    }

    &_channelList_container {
      display: none;
    }
    &_messagesHistory_base {
      width: 100%;
      display: none;
    }

    &_messagesHistory_container {
      flex-grow: 1;
    }
  }
}
