.countdown_conatiner {
  width: 350px;
  background-color: white;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  z-index: 1000000000;
  top: 10px;
  left: 10px;
  border-radius: 10px;
}