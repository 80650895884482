@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  background: #ffffff;
  width: 42%;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include body2;
    font-size: 18px;

    & > svg {
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > p {
      @include body6;
      font-size: 16px;
    }

    & > span {
      @include body6;
      font-size: 14px;
      color: var(--grey-primary);
    }

    &__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 24px 0;
      gap: 24px;

      & > div {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        & > p {
          @include body4;
          color: var(--btn-violet-primary);
          padding: 10px 16px;
          border-radius: 50px;
          border: 1px solid var(--btn-violet-primary);
        }

        & > span {
          @include body4;
          padding: 10px 16px;
          color: var(--base-white);
          background-color: var(--btn-violet-primary);
          border-radius: 50px;
        }
      }
    }
  }
}

.btns_ar {
  flex-direction: row-reverse;
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .main_container {
    width: 60%;
  }
}

@media screen and (min-width: 400px) and (max-width: 850px) {
  .main_container {
    width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .main_container {
    width: 90%;
  }

  .main_container__content__btns {
    width: 100%;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    flex-direction: column-reverse;

    & > div {
      width: 100%;

      span {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > p {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  // .btns_ar {
  //   @extend .main_container__content__btns;
  //   flex-direction: row-reverse;
  // }

  .main_container__content {
    gap: 30px;
  }
}
