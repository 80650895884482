@import '../../../../theme/scss/mixins';

.info_container {
    @include popupContainer;
    background: rgba(0, 0, 0, 0.55);
}

.decline_container {
    @include popupWrapper;
}

.info_caption {
    @include popupCaption;
}

.close_btn {
    cursor: pointer;
}

.info_content {
    margin-bottom: 50px;

    & > .length_text {
        display: inline-block;
        margin-left: 8px;
        font-size: var(--small-fsize);
        line-height: 14px;
        color: var(--black600-color);
    }
}

.textarea_wrap {
    position: relative;
    width: 100%;
    margin-bottom: 8px;

    & > textarea {
        resize: vertical;
    }

    & > img {
        top: 33px;
        right: 5px;
    }
}

.error {
    left: auto;
    right: 8px;
    bottom: -22px;
}

.btn_container {
    display: flex;
    justify-content: flex-end;
}

.send_btn {
    margin: 0 !important;
}

.loader {
    margin: 0 auto;
}

