@import '../../../theme/scss/mixins';

.info_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 300px;
	height: 100%;
	background: var(--black100-color);
}

.stepper {
	margin: 0 auto;
	padding-top: 41px;
}

.info_wrapper {
	// cursor: pointer;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 300px;
	height: 100%;
	border-radius: var(--profile-border-radius);
	background: var(--white-color);

	.test_btn {
		max-width: 100px;
		background-color: var(--primary900-color);
	}

	.info_caption {
		@include profileTitle;
	}

	.stepper {
		width: 100%;
		margin: 0 auto;

		.step_wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;

			.circle {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 30px;
				height: 30px;
				border: 2px solid #d9d9d9;
				border-radius: 50%;
				font-size: 14px;
				line-height: 21px;
				color: #a8a8a8;
			}

			.arrow {
				width: 2px;
				height: 70px;
				background-color: #d9d9d9;
			}

			.form_done {
				width: 16px;
				height: 16px;
				background: url('../../../theme/assets/icons/check_gray.svg') no-repeat;
			}
		}

		.selected .circle {
			background-color: #7fc8a7;
			border-color: #7fc8a7;
			color: #ffffff;
		}

		.selected .arrow {
			background-color: #7fc8a7;
		}

		.selected .form_done {
			background: url('../../../theme/assets/icons/check_white.svg') no-repeat;
		}
	}
}
