.main_container {
  background: #FFFFFF;
  width: 560px;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.header {
  display: flex;
  width: 100%;
  justify-content: space-between;

  p {
    font: 600 20px/28px var(--poppins-poppins-semiBold)
  }
}

.input_text_area {
  border: 1px solid #C7BCCE;
  height: 385px;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  &__next {
    width: 49%;
    padding: 10px 20px;
    background: #8450A0;
    border: none;
    border-radius: 50px;

    color: #FFFFFF;
    font-size: 16px;
    line-height: 23px;
    font-family: var(--poppins-medium-500);
    cursor: pointer;
  }

  &__cancel {
    width: 49%;
    padding: 10px 20px;
    background: none;
    border: 1px solid #D89B95;
    border-radius: 50px;

    cursor: pointer;
    color: #D89B95;
    font-size: 16px;
    line-height: 23px;
    font-family: var(--poppins-medium-500);
  }
}

.field_class {
  width: 50%;
  border-bottom: 1px solid #C7BCCE;

  &>div>div {
    padding: 0;

    &>input {
      padding-bottom: 8px;
      color: var(--main-text-color)
    }
  }
}

.loading_conatiner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
}