@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.channelsList {
  width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-right: 1px solid var(--border-color);

  background-color: var(--base-white);

  &__search {
    width: 100%;

    &__container {
      background-color: var(--gray100-background);
      border-radius: 8px;
      padding: 12.5px;
      display: flex;
      align-items: center;
      gap: 4.5px;

      &__icon {
        svg path {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  &__channelsContainer {
    width: 100%;
  }
}

.tabs {
  width: 100% !important;
}

.tabpanel {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
.profiles_container {
  padding: 0 24px;
  width: 100%;
  
  position: relative;
  
  &__profile {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    
    &>svg {
      width: 25px;
      height: 25px;
    }
  
    &>p {
      @include body2;
      max-width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  
  &__list {
    position: absolute;
    top: 35px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--history);
    border-radius: 12px;
    border: 1px solid var(--grey-secondary);
    max-height: 150px;
    max-width: 300px;
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      padding: 7px 15px;
      display: flex;
      gap: 15px;
      align-items: center;
      cursor: pointer;

      &__image {
        width: 25px;
        height: 25px;
        background-color: var(--btn-violet-secondary);
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &>img {
          width: inherit;
          height: inherit;
          border-radius: 6px;
          object-fit: cover;
        }

        &>svg {
          width: 15px;
          height: 15px;
        }
      }

      &>p {
        @include body6;
        max-width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &>span {
        @include body6;
        color: #8450a08c;
      }

      &:hover {
        color: var(--btn-violet-primary);
        text-decoration: underline;
      }
    }

  }

}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .channelsList {
    width: 100%;
    align-items: flex-start;
    padding-inline: 0;

    &__search {
      padding-inline: 24px;
    }

    &__channelsContainer > div {
      padding: 24px;
    }
  }

  .tabs {
    padding-left: 12px;
  }

  .tabpanel {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .channelsList {
    width: 100%;
    align-items: flex-start;
    padding-inline: 0;

    &__search {
      padding-inline: 24px;
    }

    &__channelsContainer > div {
      padding: 24px;
    }
  }

  .tabs {
    padding-left: 12px;
  }

  .tabpanel {
    width: 100%;
    overflow-x: hidden;
  }
}
