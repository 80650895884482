@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.main_container {
  background: #FFFFFF;
  width: 700px;
  padding: 25px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
  outline: none;
}

.header {
  position: absolute;
  right: 30px;
  top: 20px;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.stepper {
  margin-top: 25px;

  &__label {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    &>svg {
      width: 20px;
      height: 20px;

      &>path {
        stroke-width: 2;
      }
    }
  }
}

.footer {
  display: flex;
  align-items: center
}

.check_box {
  display: flex;
  gap: 15px;

  &__content {
    @include body6;
    color: var(--grey-primary)
  }
}

.btn {
  @include body6;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 40px;
  background-color: var(--btn-violet-primary);
  border-radius: 6px;
  color:#FFFFFF;
  cursor: pointer;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .main_container {
    outline: none;
    width: 500px;
  }
}

@media (max-width: 600px) {
  .main_container {
    outline: none;
    width: 95%;
  }
}

@media (max-width: 450px) {
  .footer {
    flex-direction: column;
    gap: 20px;

    &>div {
      width: 100%;
    }

    .btn {
      width: 100%;
    }
  }
}