.container {
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    background: var(--black100-color);
}

.wrapper {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 84px);
}


.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: var(--profile-border-radius);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
}

.content_title {
    width: fit-content;
    height: 128px;
    margin: 50px 0 10px;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 600;
    font-size: 68px;
    line-height: 68px;
    text-align: center;
    color: var(--black200-color);
}

.content_info {
    width: 400px;
    height: 24px;
    margin-bottom: 15px;
    font-size: var(--high-fsize);
    line-height: 24px;
    text-align: center;
    color: var(--secondary-text-color);

    & > a {
        display: inline-block;
        margin-top: 10px;
        text-decoration: underline;
        color: var(--blue600-color);
    }
}

.content_img {
    display: inline-block;
    max-height: calc(100% - 331px);
    margin: 35px 0 30px;
}
