@import '../../../theme/scss/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  position: relative;
  top: 20vh;

  &>p {
    @include body3;
  }
}