:root {
  /* color */
  --main-text-color: #262626;
  --secondary-text-color: #8c8c8c;
  --primary900-color: #e59693;
  --primary800-color: #eca9a4;
  --primary600-color: #fdd9d6;
  --primary100-color: #fcf3f3;
  --black900-color: #000000;
  --black600-color: #707070;
  --black500-color: #a8a8a8;
  --black300-color: #d9d9d9;
  --black200-color: #f0f0f0;
  --black100-color: #fafafa;
  --white-color: #ffffff;
  --blue600-color: #0595e7;
  --error-color: #dc3545;
  --warning-color: #ffc107;
  --orange-2: #f9be4c;
  --success-color: #7fc8a7;
  --type-purpure100-color: #8450a0;
  --type-purpure200-color: #6d308d;
  --type-darkblue-color: #434da1;
  --border-color: #eeeeee;
  --border-color-gray100: #eeeeee;
  --purple100-background: #fafafc;
  --purple200-background: #eee9f3;
  --purple300-backgrounde: #e9d7fa;
  --gray100-background: #f8f8fb;
  --gray200-background: #eff1f9;
  --gray300-background: #8fa0af;

  --state-selected: #f6f0fc;
  --state-success: #4a960e;
  --text-primary: #171717;
  --text-primary-light: #0e1114;
  --text-secondary: #9b9b9b;
  --text-gray: #626262;

  //REFACTORING

  //TEXT
  --base-black: #000000;
  --base-white: #ffffff;
  --text-primary: #171717;
  --text-secondary: #9b9b9b;
  --grey-primary: #626262;
  --grey-secondary: #d7d7d7;
  --tags: #626262;

  //STATES
  --error-primary: #e51616;
  --error-light: #ffe4e4;
  --success-primary: #3b8400;
  --success-light: #f8ffeb;
  --selected: #f6f0fc;

  //BUTTONS
  --btn-violet-primary: #8450a0;
  --btn-violet-primary-focused: #6d308d;
  --btn-violet-secondary: #eee9f3;
  --btn-violet-disabled: #c7bcce;
  --btn-pink-primary: #d89b95;
  --btn-pink-primary-focused: #cb766e;
  --btn-pink-secondary: #faf1f0;
  --btn-orange-primary: #b15907;
  --btn-orange-secondary: #fff1e4;

  //BACKGROUND
  --main: #fafafc;
  --base-50: #eeeeee;
  --base-100: #f4f6f7;
  --history: #fdfcff;
  --back-tags: #fff9e9;
  --search-input: #f8f8fb;

  /* font-size */
  --main-fsize: 14px;
  --caption-fsize: 36px;
  --popup-title-fsize: 24px;
  --high-fsize: 18px;
  --title-fsize: 16px;
  --small-fsize: 12px;

  /* dimensions */
  --desktop-height: 73px;
  --main-border-radius: 8px;
  --input-border-radius: 50px;
  --profile-border-radius: 18px;
  --popup-border-radius: 20px;

  /* font family*/
  --poppins-light: Poppins_Light, sans-serif;
  --poppins-regular: Poppins_Regular, sans-serif;
  --poppins-medium-500: Poppins_Medium_500, sans-serif;
  --poppins-poppins-semiBold: Poppins_SemiBold, sans-serif;
  --poppins-poppins-bold: Poppins_Bold, sans-serif;
}
