@import '../../../theme/scss/mixins';

.main_container {
  position: absolute;
  background: #ffffff;
  width: 700px;
  min-height: 400px;
  max-height: 95%;
  padding: 24px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.container_scroll {
  display: flex;
  flex-direction: column;
  max-height: 900px;
  overflow-y: scroll;
}
.container_scroll::-webkit-scrollbar {
  display: none;
}

.items_container {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding-right: 10px;
}

.item_container {
  width: 100%;
  display: flex;
  align-items: center;

  & > span {
    @include body6;
    width: 30%;
    color: var(--grey-primary);
  }
}

.avatar_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  & > img {
    position: relative;
    display: inline-block;
    width: 200px;
    height: 200px;
    margin: 10px auto;
    border-radius: 50%;
  }

  &__edit {
    position: absolute;
    top: 45%;
    z-index: 2;

    & > svg {
      width: 30px;
      height: 30px;
      display: none;
    }
  }

  &:hover {
    filter: brightness(80%);
    cursor: pointer;

    .avatar_container__edit > svg {
      display: block;
    }
  }
}

.width {
  width: 100%;

  & > div {
    width: 100%;

    & > div {
      width: 100%;

      & > input {
        @include body6;
        width: 100%;
      }
    }
  }
}

.form_field_country {
  width: 100%;

  & > div {
    width: 100%;

    & > img + input {
      padding: 8px 12px 8px 40px !important;
    }

    input {
      width: 100%;
    }
  }
}

.documents_conatiner {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    @include body6;
  }

  &__documents_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__title {
      display: flex;
      align-items: center;
      gap: 12px;
      width: max-content;

      .icon {
        border-radius: 100%;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--btn-violet-secondary);
        cursor: pointer;

        & > svg {
          width: 25px;
          height: 25px;

          & > path {
            stroke: var(--btn-violet-primary);
          }
        }
      }

      & > p {
        @include body6;
        cursor: pointer;
      }
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &__item {
      width: 49%;
      border-radius: 999px;
      border: 1px solid var(--btn-violet-secondary);
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 3px;

      & > svg {
        width: 16px;
        height: 16px;
      }

      & > p {
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include body6;
        color: var(--btn-violet-primary);
      }

      & > span {
        @include subtext3;
        color: var(--text-secondary);
      }
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  &__btn {
    width: 150px;
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: var(--btn-violet-primary);
    color: var(--base-white);
    @include body2;
    border-radius: 50px;
    cursor: pointer;
    border: none;
  }

  &__btn_disabled {
    width: 150px;
    padding: 10px;
    display: flex;
    justify-content: center;
    background-color: var(--btn-violet-disabled);
    color: var(--base-white);
    @include body2;
    border-radius: 50px;
    cursor: pointer;
    border: none;
  }
}

.password_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.width_50 {
  width: 49%;
}

.width_100 {
  width: 100%;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .main_container {
    width: 90%;
  }
}

@media (max-width: 600px) {
  .main_container {
    width: 95%;
  }

  .password_container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
