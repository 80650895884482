@import '../../../theme/scss/mixins.scss';

h2 {
  font-family: var(--poppins-poppins-semiBold);
  font-size: 20px;
  line-height: 28px;
  cursor: pointer !important;
}

.content_div {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
  justify-content: space-between;

  .history_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    gap: 24px;
    background-color: var(--base-white);
    border-radius: 12px;
    // padding: 24px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-family: var(--poppins-poppins-semiBold);
        font-size: 16px;
        line-height: 22px;
      }

      span {
        font-family: var(--poppins-regular);
        font-size: 16px;
        line-height: 22px;
        color: #626262;
      }
    }
  }
}

.violet_btn {
  @include body2;
  color: var(--btn-violet-primary);
  cursor: pointer;
  text-decoration: underline;
}

.parent_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
