.therapist_container {
  display: none;
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .therapist_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 72px;
    box-shadow: 0px 1px 30px 0px rgba(157, 157, 209, 0.16);
  }
  .therapist {
    display: flex;
    align-items: center;
    width: 50%;
    max-width: 744px;

    &_arrow_left {
      margin-left: 16px;
      margin-right: 16px;
      cursor: pointer;
    }
    &_img_and_name {
      display: flex;
    }
    &_full_name {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (max-width: 600px) {
  .therapist_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    height: 72px;
    box-shadow: 0px 1px 30px 0px rgba(157, 157, 209, 0.16);
  }
  .therapist {
    display: flex;
    align-items: center;
    width: 100%;

    &_arrow_left {
      margin-left: 16px;
      margin-right: 16px;
      cursor: pointer;
    }
    &_img_and_name {
      display: flex;
    }
    &_full_name {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
