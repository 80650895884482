@import '../../theme/scss/mixins.scss';

.main {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    @include body3;
    text-align: center;
  }

  h3 {
    text-align: center;
  }
}

.text_error {
  @extend .text;
  position: relative;
  top: 17%;
}



.img_almost_there {
  width: 560px;
  height: 500px;
  margin-top: 10px;
}