.auth {
  display: flex;
  width: 100%;
  overflow-y: scroll;
  color: var(--main-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
