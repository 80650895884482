@import '../../../theme/scss/mixins';

/* Text field */

.text_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  position: relative;

  & > label {
    @include body6;
    margin: 0 0 8px 0;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }

  & > input,
  textarea {
    @include body3;
    width: 100%;
    height: 56px;
    padding: 8px 10px;
    border: 1px solid var(--black300-color);
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    color: var(--main-text-color);

    &::placeholder {
      @include body3;
      color: var(--secondary-text-color);
    }
  }
}

.phone_number_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  position: relative;

  &__country_div {
    position: absolute;
  }

  & > label {
    @include body6;
    margin: 0 0 8px 0;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }

  & > input {
    @include body3;
    width: 100%;
    height: 56px;
    padding: 8px 10px;
    border: 1px solid var(--black300-color);
    border-radius: 5px;
    outline: none;
    box-sizing: border-box;
    color: var(--main-text-color);

    &::placeholder {
      @include body3;
      color: var(--secondary-text-color);
    }
  }
}

/* TextArea field */

.text_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  
  &>p{
    color: var(--grey-primary);
    margin-top: 15px;
  }

  .label_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;


    & > label {
      margin: 0 0 8px 0;
      font-size: var(--main-fsize);
      line-height: 22px;
      color: var(--main-text-color);
  
      span {
        color: var(--error-color);
      }
    }
  }


  & > textarea {
    min-height: 100px;
    padding: 8px 10px;
    resize: none;
    border-radius: 10px;
    outline: none;
    font-family: var(--poppins-regular);
    font-size: 16px;
    line-height: 24px;
    box-sizing: border-box;
    color: var(--main-text-color);

    div {
      font-family: Popping_Regular, sans-serif;
      color: var(--secondary-text-color);
    }
  }
}

/* Password field */

.password_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
  height: fit-content;

  & > label {
    @include body6;
    margin: 0 0 8px 0;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }

  & > input {
    @include body3;
    width: 100%;
    height: 56px;
    padding: 8px 10px;
    border: 1px solid var(--black300-color);
    border-radius: 5px;
    outline: none;
    color: var(--main-text-color);
    box-sizing: border-box;

    &::placeholder {
      @include body3;
      color: var(--secondary-text-color);
    }
  }
}

// Multiple autocomplete field

.multiple_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;

  & > label {
    margin: 0 0 8px 0;
    font-size: var(--main-fsize);
    line-height: 22px;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }
}

.multiple_field {
  width: 100%;
  height: fit-content;

  & > div[role='presentation'] {
    background-color: red !important;
  }

  & > div {
    width: 100%;
    height: fit-content;

    & > div {
      width: 100%;
      min-height: 42px;
      border: 1px solid var(--black300-color) !important;
      outline: none !important;
      border-radius: 5px !important;
      padding: 0 0 0 10px !important;
      background-color: var(--base-white);

      & div {
        max-width: 40% !important;
        height: 26px !important;
        @include body6;
      }

      & > span {
        margin-left: 10px !important;
      }

      & > div {
        & button:last-child {
          display: none !important;
        }
      }

      & > fieldset {
        display: none;
      }

      & > input {
        @include body6;
        padding: 8px 10px;
        font-size: var(--main-fsize);
        line-height: 24px;
        height: 56px;
        color: var(--main-text-color);
        box-sizing: border-box;

        &::placeholder {
          @include body3;
          color: var(--secondary-text-color) !important;
          opacity: 1;
        }
      }
    }
  }
}

/* Autocomplete field */

.autocomplete_wrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  position: relative;

  & > label {
    margin: 0 0 8px 0;
    font-size: var(--main-fsize);
    line-height: 22px;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }
}

.autocomplete_field {
  width: 100%;
  height: fit-content;

  & > div {
    @include body3;
    position: relative;
    width: 100%;
    height: fit-content;

    & > input {
      @include body3;
      width: 100%;
      height: 56px;
      padding: 8px 50px 8px 10px;
      border: 1px solid var(--black300-color);
      border-radius: 5px;
      outline: none;
      // font-size: var(--main-fsize);
      // line-height: 24px;
      color: var(--main-text-color);
      box-sizing: border-box;

      &::placeholder {
        @include body3;
        color: var(--secondary-text-color);
      }
    }

    & > img {
      position: absolute;
      top: 40%;
      left: 12px;
      display: block;
    }
  }
}

div[role='presentation'] {
  // Autocomplete drop list styles
  // padding: 0 !important;
  //min-height: 260px !important;
  & div {
    // padding: 0 !important;

    & > ul {
      padding: 0 !important;
      box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12);
      //border-radius: var(--input-border-radius);
      font-size: var(--main-fsize);
      line-height: 22px;
      color: var(--main-text-color);
      background: var(--white-color);

      & li {
        @include body3;
        padding: 5px 12px !important;

        & > img {
          margin-right: 8px;
        }
      }
    }
  }
}

/* Radio button */

.radio_label {
  margin: 0 0 8px 10px;
  font-size: 14px;
  line-height: 17px;

  & > span {
    color: var(--error-color);
  }
}

.radio_wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin-bottom: 0;

  & span:hover {
    background: none !important;
  }

  & span:last-child {
    // Label styles
    font-family: Popping_Regular, sans-serif !important;
    font-size: var(--main-fsize);
    line-height: 22px;
    color: var(--main-text-color);
  }

  & span:first-child {
    // Wrapper size
    padding: 5px 9px !important;

    & > span {
      & > svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}

/* Checkbox button */

.checkbox_wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin-right: 0 !important;

  & span {
    padding: 0 0 0 9px;
  }

  & span:hover {
    background: none !important;
  }

  & span:last-child {
    // Label styles
    font-size: var(--small-fsize);
    line-height: 16px;
    color: var(--main-text-color);
  }

  & span:first-child {
    // Wrapper size
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
}

/* Upload field */

.upload_wrapper {
  position: relative;
  margin-bottom: 24px;

  & > h6 {
    margin-bottom: 8px;
    font-size: var(--main-fsize);
    line-height: 22px;
    color: var(--main-text-color);
  }

  & > div {
    & > input {
      position: absolute;
      height: 0.4px;
      width: 0.4px;
    }

    & > label {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 200px;
      padding: 16px;
      border: 1px dashed var(--black300-color);
      border-radius: 18px;
      text-align: center;
      background: #f5f5f5;
      cursor: pointer;

      .upload_title {
        padding: 16px 0 8px;
        font-family: 'Poppins_Regular', sans-serif;
        font-size: var(--title-fsize);
        line-height: 24px;
        color: var(--main-text-color);
      }

      .upload_description {
        width: 96%;
        font-family: 'Poppins_Regular', sans-serif;
        font-size: var(--main-fsize);
        line-height: 22px;
        color: var(--secondary-text-color);
      }
    }
  }

  .docs_wrap {
    height: 200px;
    border: 1px dashed var(--black300-color);
    border-radius: 18px;
  }

  .docs_wrapper {
    max-height: 152px;
    overflow-y: scroll;
  }

  .doc_item {
    padding: 8px 20px;
    font-size: var(--main-fsize);
    line-height: 24px;
    border-bottom: 1px solid var(--black300-color);
    color: #000000;
    cursor: pointer;

    & img {
      margin: 0 8px -2px 0;
    }

    &:hover {
      background: #fafafa;
    }
  }

  .doc_item:last-child {
    border-bottom: none;
  }

  .doc_title {
    display: flex;
    justify-content: space-between;
    color: #a8a8a8;

    & label,
    span:last-child {
      position: relative;
      font-size: 12px;
      line-height: 24px;
      color: #0595e7;
      cursor: pointer;
    }

    label:before {
      content: url('../../../theme/assets/icons/download.svg');
      position: absolute;
      left: -22px;
      top: 3px;
    }

    span:last-child:before {
      content: url('../../../theme/assets/icons/delete_blue.svg');
      position: absolute;
      left: -22px;
      top: 3px;
    }
  }

  .doc_title:hover {
    background: none;
    cursor: initial;
  }
}

/* DatePicker field */

.date_wrapper {
  display: flex;
  flex-direction: column;

  & > label {
    @include body6;
    margin: 0 0 8px 0;
    color: var(--main-text-color);

    span {
      color: var(--error-color);
    }
  }

  .date_input {
    @include body3;
    width: 100%;
    height: 56px;
    padding: 8px 10px;
    border: 1px solid var(--black300-color);
    border-radius: 5px;
    outline: none;
    color: var(--main-text-color);
    box-sizing: border-box;

    &::placeholder {
      @include body3;
      color: var(--secondary-text-color);
    }
  }

  div {
    div {
      position: relative;

      button {
        position: absolute;
        bottom: 15px;
        right: 25px;
        width: 22px;
        height: 22px;
        padding: 0;

        svg {
          width: 100%;
        }
      }
    }
  }
}

// Errors

.error {
  @include errorField;
}

.error_icon {
  @include errorIcon;
}

.error_wraper {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 5px;
  bottom: -18px;
}

.error_radio {
  font-size: var(--small-fsize);
  line-height: 14px;
  white-space: nowrap;
  color: var(--error-color);
  margin-left: 10px;
  margin-bottom: 10px;
}

.icon_wrap {
  position: absolute;
  right: 8px;
  top: 46px;
  cursor: pointer;
}

.icon_wrap_right {
  @extend .icon_wrap;
  right: 10px;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.hint_message {
  @include subtext3;
  color: var(--grey-primary);
}

@media (max-width: 600px) {
  .password_wrapper {
    width: 100% !important;
  }
}
