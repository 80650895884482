@import '../../../theme/scss/mixins.scss';

.tabs {
  display: flex;
}

.tab {
  display: flex;
  margin-right: 40px;

  & span {
    @include body2;
    color: var(--grey-primary);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.chat {
  position: relative;

  & > svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 3px;
    right: -30px;

    animation: bounce 1s infinite;

    & > path {
      fill: var(--btn-pink-primary);
    }
  }
}

.active {
  @extend .tab;

  & span {
    box-sizing: border-box;
    color: var(--text-primary);
    padding-bottom: 10px;
    border-bottom: 2px solid var(--btn-pink-primary);
  }
}

@media (max-width: 600px) {
  .tabs {
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 0px;
    gap: 16px;
  }
  .tab {
    margin-right: 0;
    // padding-top: 40px;

    &:first-child {
      padding-top: 0;
    }
  }
}
