@import '../../../../theme/scss/mixins';

.profile_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 600px;
  min-height: 250px;
  padding: 24px;
  border-radius: 16px;
  background: #ffffff;
  outline: none;

  .profile_title {
    text-align: center;
    font-size: 24px;
  }
  .profile_close_icon {
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;
  }
  .profile_question {
    text-align: center;
  }
  .profile_buttons {
    display: flex;
    justify-content: center;
  }
  .profile_next_block {
    display: flex;
    justify-content: center;

    .profile_next {
      @include body2;
      display: flex;
      justify-content: center;
      width: 200px;
      color: var(--base-white);
      cursor: pointer;
      background-color: var(--btn-violet-primary);
      padding: 10px 20px;
      border-radius: 50px;
    }
  }
}
