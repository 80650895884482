.container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & :nth-child(1){
    color: var(--black600-color);
  }

  & :nth-child(2){
    margin-top: 34px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;

    color: var(--black600-color);
  }

}