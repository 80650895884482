@import '../../../../theme/scss/mixins.scss';
@import '../../../../theme/scss/variables.scss';

.main_container {
  padding: 40px 102px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 68vh;
  max-height: 70vh;
  overflow: scroll;
  padding-right: 12px;

  &__note_container {
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    min-height: 250px;

    position: relative;
    border-radius: 12px;
    background-color: var(--base-white);

    &__content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      & > span {
        @include body5;
      }
    }

    & > p {
      bottom: 24px;
      @include body2;
    }

    &:hover {
      background-color: var(--btn-violet-secondary);
      cursor: pointer;
    }
  }
}

.btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  & > div {
    @include body2;
    cursor: pointer;
    border-radius: 50px;
    width: 178px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: var(--btn-violet-primary);
    color: var(--base-white);
  }
}

@media (max-width: 600px) {
  .main_container {
    padding: 40px 16px;
  }
}
