@import '../../../../theme/scss/mixins';

@import '../../../../theme/scss/mixins.scss';

.main_container {
    background: #FFFFFF;
    width: 436px;
    padding: 64px 24px 24px 24px;
    position: absolute;
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 40px;

    .icon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__form {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &__item {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &__text {
                    @include body6;
                }
            }

            &__text_field {
                &>label {
                    @include body6;
                }
            }
        }
    }
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.btn {
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 50px;

    @include body2;
    cursor: pointer;

    &__btn_save {
        @extend .btn;
        background: var(--btn-violet-primary);
        color: var(--base-white)
    }

    &__btn_save_disabled {
        @extend .btn;
        background-color: var(--btn-violet-disabled);
        cursor: default;
        color: var(--base-white);
    }

    &__btn_cancel {
        @extend .btn;
        background: none;
        border: 1px solid var(--btn-pink-primary);
        color: var(--btn-pink-primary)
    }
}