@import '../../../theme/scss/mixins.scss';

.main_container {
  width: 436px;
  height: max-content;
  background-color: var(--base-white);
  border-radius: 12px;
  box-shadow: 0px 7px 34px 0px rgba(0, 0, 0, 0.11);
  padding: 24px;
  display: flex;
  gap: 16px;
  position: absolute;
  z-index: 2;
  right: 8px;

  .icon_conatiner {
    min-width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: var(--btn-violet-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text_container {
    max-width: 75%;
    display: flex;
    flex-direction: column;

    & p {
      @include body2;
    }

    & span {
      @include body6;
      color: var(--grey-primary);
    }

    &__user {
      @include link1;
      color: var(--btn-violet-primary) !important;
    }
  }

  .icon {
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .main_container {
    width: 90%;
    left: 0;
    right: 0;
    margin: 16px auto;
  }
}
