@import '../../../theme/scss/mixins.scss';
@import '../../../theme/scss/variables.scss';

.right_align {
  direction: rtl;
}

.main_container {
  // height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  background-color: var(--base-white);
  border-radius: 16px;
  &__subtitle {
    font-size: 12px;
    font-weight: lighter;
    color: var(--black600-color);
  }
  &__btn {
    width: max-content;
    @include body2;
    color: var(--base-white);
    display: flex;
    gap: 16px;
    background-color: var(--btn-violet-primary);
    border-radius: 50px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
  }

  &__btn__secondary {
    width: max-content;
    @include body2;
    color: var(--btn-violet-primary);
    display: flex;
    gap: 16px;
    background-color: var(--base-white);
    border-radius: 50px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border: 1px solid var(--btn-violet-primary);
  }

  &__space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__flex__center {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__questionnaire {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > p {
        @include body1;
        font-size: 18px;
        color: #bca0cd;
      }

      & > span {
        @include body6;
        font-size: 18px;
      }

      & > div {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .advisor {
          @include body3;

          & > span {
            @include body2;
          }
        }

        & > p {
          @include body2;
          color: var(--btn-violet-primary-focused);
        }

        & > span {
          @include body6;
        }
      }

      &__sub {
        padding-left: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include body6;
        margin: 12px 0;

        & > div {
          & > span {
            @include body2;
            color: var(--btn-violet-primary-focused);
          }
        }
      }
    }

    .optional_sections {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > h6 {
        font-size: 18px;
        color: #bca0cd;
      }

      &>p {
        @include body2;
        color: var(--btn-violet-primary-focused);
      }

      &__item {
        & > span {
          @include body6;
          font-size: 16px;
        }

        & > div {
          @include body3;

          & > p {
            @include body2;
            color: var(--btn-violet-primary-focused);
          }
        }
      }
    }
  }
}
