@font-face {
  font-family: 'Poppins_Light';
  src: url('../assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins_Regular';
  src: url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins_Bold';
  src: url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins_Medium_500';
  src: url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins_SemiBold';
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}
