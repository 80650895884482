.loadingSpinner {
  position: relative;
  margin: 0 auto;
}

.loadingSpinnerInner {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 3px solid;
  animation: spinner-rotate 1s infinite linear;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spinner-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}