.main_container {
  background: #FFFFFF;
  height: 90vh;
  width: 60%;
  max-width: 80%;
  padding: 25px;
  position: absolute;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  position: absolute;
  right: 50px;
  top: 30px;

  &__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}